export default function Blog() {
  return (
    <div className="container-fluid">
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          className="embed-responsive-item"
          src="https://nxc0521.uta.cloud/"
          title="Blog"
        ></iframe>
      </div>
    </div>
  );
}

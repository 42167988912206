import env from "../../../env";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import validator from "validator";
import { toast } from "react-toastify";
import axios from "axios";

export default function ResetPassword() {
  const baseUrl = env.BASE_URL;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCpassword] = useState("");
  let isError = false;

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!email.length) {
      fieldName.push("Email");
    }
    if (!password.length) {
      fieldName.push("Password ");
    }
    if (!cPassword.length) {
      fieldName.push("Confirm Password ");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error("Email is Invalid!");
      isError = true;
    }
    if (password !== cPassword) {
      toast.error("Passwords do not match!");
      isError = true;
    }

    if (isError) {
      return;
    }
    resetPassword();
  };

  async function resetPassword() {
    const resetPasswordUrl = baseUrl + "/reset-password";
    const body = {
      email,
      password,
      token,
      password_confirmation: cPassword,
    };
    try {
      const response = await axios.post(resetPasswordUrl, body);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  }

  return (
    <div className="row">
      <div
        className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
        style={{ backgroundImage: "url(/images/login-bg-2.jpg)" }}
      />
      <div className="col-xl-7 vh-lg-100 align-items-center d-flex bg-white rounded-lg overflow-hidden">
        <div className="card shadow-none border-0 ml-auto mr-auto login-card">
          <div className="card-body rounded-0 text-left">
            <h2 className="fw-700 display1-size display2-md-size mb-4">
              Reset Password
            </h2>
            <form className="mb-3">
              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-email text-grey-500 pr-0" />
                <input
                  type="text"
                  className="style2-input pl-5 form-control text-grey-900 font-xsss fw-600"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="form-group icon-input mb-3">
                <input
                  type="Password"
                  className="style2-input pl-5 form-control text-grey-900 font-xss ls-3"
                  placeholder="New Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <i className="font-sm ti-lock text-grey-500 pr-0" />
              </div>
              <div className="form-group icon-input mb-1">
                <input
                  type="Password"
                  className="style2-input pl-5 form-control text-grey-900 font-xss ls-3"
                  placeholder="Confirm New Password"
                  onChange={(e) => setCpassword(e.target.value)}
                  value={cPassword}
                />
                <i className="font-sm ti-lock text-grey-500 pr-0" />
              </div>
            </form>
            <div className="col-sm-12 p-0 text-left">
              <div className="form-group mb-1">
                <Link
                  className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                  onClick={(e) => handleSubmit(e)}
                >
                  Change Password
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

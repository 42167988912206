import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  messageCount: null,
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMessageCount(_state, action) {
      const state = _state
      state.messageCount = action.payload
    },
  },
})

// Reducer
export default slice.reducer

// Actions
export const { setMessageCount } = slice.actions

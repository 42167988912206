import { useNavigate, Outlet, Navigate } from "react-router-dom";
import Header from "./Header";

export default function AuthLayout() {
  const authToken = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  useNavigate();
  return authToken && role ? (
    <Navigate to={"/dashboard/" + role} />
  ) : (
    <div>
      <div className="main-wrap">
        <Header />
        <Outlet />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import env from "../../../../env";
import axios from "axios";

function ManageCourses(props) {
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");

  const [activeId, setActiveId] = useState(-1);
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState(null);
  const [courses, setCourses] = useState([]);
  const [studentCourses, setStudentCourses] = useState([]);

  const navigate = useNavigate();
  const [selectedCourses, setSelectedCourses] = useState([]);
  let isError = false;
  const [mode, setMode] = useState("add");

  const title = props.title;
  const dis = props.dis;

  useEffect(() => {
    if (!students.length) {
      getStudents();
      getCourses();
      getStudentCourses();
    }
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getStudents = () => {
    axios
      .get(baseUrl + "/role-users", {
        ...config,
        params: {
          role: "student",
        },
      })
      .then((response) => {
        const studs = [];
        response.data.users.forEach((student) => {
          studs.push({ value: student.id, label: student.name });
        });
        setStudents(studs);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getCourses = () => {
    axios
      .get(baseUrl + "/courses", config)
      .then((response) => {
        const cours = [];
        response.data.courses.forEach((course) => {
          cours.push({ value: course.id, label: course.name });
        });
        setCourses(cours);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getStudentCourses = () => {
    axios
      .get(baseUrl + "/admin/student-courses", config)
      .then((response) => {
        setStudentCourses(response.data.studentCourses);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleStudentChange = (selectedValues) => {
    setStudent(selectedValues);
  };

  const handleCourseSelection = (selectedValues) => {
    setSelectedCourses(selectedValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!student) {
      toast.error("Please select a Student!");
      return;
    }
    if (selectedCourses.length === 0) {
      isError = true;
      fieldName.push("Courses Selection");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "add") {
      addStudentCourse();
    } else {
      updateStudentCourse();
    }
  };

  const clearFields = () => {
    setStudent("");
    setSelectedCourses([]);
    setMode("add");
  };

  const addStudentCourse = () => {
    const newStudent = {
      student_id: student,
      courses: selectedCourses.map((course) => course.value),
    };
    axios
      .post(baseUrl + "/admin/student-courses", newStudent, config)
      .then((response) => {
        toast.success(response.data.message);
        getStudentCourses();
      });
    clearFields();
  };

  const editStudentCourse = (id) => {
    setMode("edit");
    setActiveId(id);
    const studentCourse = studentCourses.find((student) => student.id === id);
    setStudent(studentCourse.student_id);
    setSelectedCourses(
      studentCourse.courses.map((course) => {
        return { value: course.id, label: course.name };
      })
    );
  };

  const deleteStudentCourse = (id) => {
    axios
      .delete(baseUrl + "/admin/student-courses/" + id, config)
      .then((response) => {
        toast.success(response.data.message);
        getStudentCourses();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
    clearFields();
  };

  const updateStudentCourse = () => {
    const newStudent = {
      student_id: student,
      courses: selectedCourses.map((course) => course.value),
    };
    axios
      .post(baseUrl + "/admin/student-courses/" + activeId, newStudent, config)
      .then((response) => {
        toast.success(response.data.message);
        getStudentCourses();
      });
    clearFields();
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">{title}</h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Student</label>
                <Select
                  isSearchable={false}
                  options={students}
                  value={students.find((option) => option.value === student)}
                  onChange={(e) => (e ? handleStudentChange(e.value) : "")}
                  placeholder="Select a Student"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Courses</label>
                <Select
                  isMulti
                  options={courses}
                  onChange={handleCourseSelection}
                  value={selectedCourses}
                  placeholder="Select Courses"
                />
              </div>
            </div>
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3 float-right">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={(e) => handleSubmit(e)}
              >
                {mode === "add" ? "Add" : "Edit"} {dis}
              </Link>
            </div>
          </div>
        </form>
      </div>
      {studentCourses?.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Registration ID</th>
                <th className="bg-current text-white">Student Name</th>
                <th className="bg-current text-white">Courses Registered</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {studentCourses?.map((studentCourse) => (
                <tr key={studentCourse.id}>
                  <td>{studentCourse.id}</td>
                  <td>{studentCourse.student.name}</td>
                  <td>
                    {studentCourse.courses
                      .map((course) => course.name)
                      .join(", ")}
                  </td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editStudentCourse(studentCourse.id)}
                      onKeyDown={(e) => {}}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteStudentCourse(studentCourse.id)}
                      onKeyDown={(e) => {}}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ManageCourses;

import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Messages from "../../pages/dashboard/Messages.js";

export default function Navigation() {
  const { messageCount } = useSelector((state) => state.app);

  const location = useLocation();

  const getUserRole = () => {
    return localStorage.getItem("role");
  };
  const userRole = getUserRole();

  return (
    <nav className="navigation scroll-bar">
      <div className="d-none">
        <Messages />
      </div>
      <div className="container pl-0 pr-0">
        <div className="nav-content">
          <div className="nav-top">
            <Link to="/">
              <i className="feather-package text-success display1-size mr-3 ml-3" />
              <span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xl logo-text mb-0">
                Acumenify
              </span>
            </Link>
            <Link className="close-nav d-inline-block d-lg-none">
              <i className="ti-close bg-grey mb-4 btn-round-sm font-xssss fw-700 text-dark ml-auto mr-2" />
            </Link>
          </div>
          <div className="nav-caption fw-600 font-xssss text-grey-500">
            <span>Actions</span>
          </div>
          <ul className="mb-3">
            <li className="logo d-none d-xl-block d-lg-block" />
            <li>
              <NavLink
                to={"/dashboard/" + userRole}
                className={
                  "nav-content-bttn open-font" +
                  location.pathname.indexOf(userRole + "/")
                    ? ""
                    : " active"
                }
                data-tab="favorites"
              >
                <i className="feather-shield mr-3" />
                <span>Manage</span>
              </NavLink>
            </li>
            <li className="logo d-none d-xl-block d-lg-block" />
            {userRole === "student" && (
              <li>
                <NavLink
                  to="/dashboard/exam"
                  class={
                    "nav-content-bttn open-font" +
                    location.pathname.indexOf("exam")
                      ? " active"
                      : ""
                  }
                  data-tab="favorites"
                >
                  <i class="feather-book-open mr-3"></i>
                  <span>Exam</span>
                </NavLink>
              </li>
            )}
          </ul>
          <div className="nav-caption fw-600 font-xssss text-grey-500">
            <span /> Account
          </div>
          <ul className="mb-3">
            <li className="logo d-none d-xl-block d-lg-block" />
            <li>
              <Link
                to="/dashboard/settings"
                className="nav-content-bttn open-font h-auto pt-2 pb-2"
              >
                <i className="font-sm feather-settings mr-3 text-grey-500" />
                <span>Settings</span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/messages"
                className="nav-content-bttn open-font h-auto pt-2 pb-2"
              >
                <i className="font-sm feather-message-square mr-3 text-grey-500" />
                <span>Chat</span>
                {messageCount > 0 && (
                  <span className="circle-count bg-warning mt-0">
                    {messageCount}
                  </span>
                )}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

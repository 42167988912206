import env from "../../../env";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

export default function VerifyEmail() {
  const baseUrl = env.BASE_URL;
  const location = useLocation();
  const url = location.search.split("?url=")[1];

  const [emailVerified, setEmailVerified] = useState(false);

  const apiCall = async () => {
    const verifyUrl = baseUrl + "/email/verify" + url;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    try {
      const response = await axios.get(verifyUrl, {
        headers,
      });
      toast.success(response.data.message);
      setEmailVerified(true);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (url && !emailVerified) {
      apiCall();
    }
  });

  return (
    <div className="row">
      <div
        className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
        style={{ backgroundImage: "url(/images/login-bg.jpg)" }}
      />
      <div className="col-xl-7 vh-lg-100 align-items-center d-flex bg-white rounded-lg overflow-hidden">
        <div className="card shadow-none border-0 ml-auto mr-auto login-card">
          {!emailVerified ? (
            <div className="card-body rounded-0 text-left">
              <h2 className="fw-700 display1-size display2-md-size mb-3">
                Please Wait!
              </h2>
              <div className="col-sm-12 p-0 text-left">
                <p className="fw-600">
                  Your email is being verified.
                  <br />
                  Please wait for a while.
                  <br />
                  <br />
                  If you didn't get a success message, please contact support.
                </p>
              </div>
            </div>
          ) : (
            <div className="card-body rounded-0 text-left">
              <h2 className="fw-700 display1-size display2-md-size mb-3">
                Email Verified!
              </h2>
              <div className="col-sm-12 p-0 text-left">
                <p className="fw-600">
                  Thank You! Your email has been verified. Your account is
                  active now.
                  <br />
                  Please login to start using your account
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";

function ManageCoordinator() {
  return (
    <div className="row">
      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
        <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-xxl-5 p-4 border-0 text-center">
          <Link
            to="/dashboard/coordinator/program-overview-and-alignment"
            className="p-2 mt-4 mb-2 d-inline-block text-white fw-700 lh-30 rounded-lg w300 text-center font-xsssss ls-3 bg-current"
          >
            Program Overview and Alignment
          </Link>
          <Link
            to="/dashboard/coordinator/curriculum-development-and-updates"
            className="p-2 mt-4 mb-2 d-inline-block text-white fw-700 lh-30 rounded-lg w300 text-center font-xsssss ls-3 bg-current"
          >
            Curriculum Development and Updates
          </Link>
          <Link
            to="/dashboard/coordinator/program-evaluation"
            className="p-2 mt-4 mb-2 d-inline-block text-white fw-700 lh-30 rounded-lg w300 text-center font-xsssss ls-3 bg-current"
          >
            Program Evaluation
          </Link>
          <Link
            to="/dashboard/coordinator/support-and-enquiries"
            className="p-2 mt-4 mb-2 d-inline-block text-white fw-700 lh-30 rounded-lg w300 text-center font-xsssss ls-3 bg-current"
          >
            Support & Enquiries
          </Link>
          <Link
            to="/dashboard/coordinator/reports"
            className="p-2 mt-4 mb-2 d-inline-block text-white fw-700 lh-30 rounded-lg w300 text-center font-xsssss ls-3 bg-current"
          >
            Reports
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ManageCoordinator;

import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import validator from "validator";
import env from "../../../env";

export default function ForgotPassword() {
  const baseUrl = env.BASE_URL;

  const [email, setEmail] = useState("");
  let isError = false;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email.length) {
      toast.error("Email is Required!");
      isError = true;
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error("Email is Invalid!");
      isError = true;
    }

    if (isError) {
      return;
    }
    sendPasswordResetLink();
  };

  function sendPasswordResetLink() {
    axios
      .post(
        baseUrl + "/forgot-password",
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success(response.data?.message);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(`${error.response?.data?.message}`);
      });
  }

  return (
    <div className="row">
      <div
        className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
        style={{ backgroundImage: "url(/images/login-bg-2.jpg)" }}
      />
      <div className="col-xl-7 vh-lg-100 align-items-center d-flex bg-white rounded-lg overflow-hidden">
        <div className="card shadow-none border-0 ml-auto mr-auto login-card">
          <div className="card-body rounded-0 text-left">
            <h2 className="fw-700 display1-size display2-md-size mb-4">
              Forgot Password
            </h2>
            <form>
              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-email text-grey-500 pr-0" />
                <input
                  type="text"
                  className="style2-input pl-5 form-control text-grey-900 font-xsss fw-600"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
            </form>
            <div className="col-sm-12 p-0 text-left">
              <div className="form-group mb-1">
                <Link
                  className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                  onClick={(e) => handleSubmit(e)}
                >
                  Send Password Reset Link
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

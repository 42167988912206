import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import env from "../../../../env";
import axios from "axios";

function FacultyDevelopment() {
  const navigate = useNavigate();
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let isError = false;

  const [mode, setMode] = useState("add");
  const [fetchFaculties, setFetchFaculties] = useState(false);
  const [faculties, setFaculties] = useState([]);
  const [facultyDevelopments, setFacultyDevelopments] = useState([]);
  const [facultyDevelopment, setFacultyDevelopment] = useState([]);
  const [faculty, setFaculty] = useState("");
  const [skillsToDevelop, setSkillsToDevelop] = useState("");
  const statusOptions = [
    { value: "todo", label: "Todo" },
    { value: "in-progress", label: "In Progress" },
    { value: "completed", label: "Completed" },
    { value: "failed", label: "Failed" },
  ];
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (!fetchFaculties) {
      getFaculties();
      getFacultyDevelopments();
    }
  });

  const getFaculties = () => {
    axios
      .get(baseUrl + "/users", config)
      .then((response) => {
        const usrs = [];
        response.data.users.forEach((user) => {
          if (
            user.role === "instructor" ||
            user.role === "coordinator" ||
            user.role === "qa"
          ) {
            usrs.push({ value: user.id, label: user.name });
          }
        });
        setFaculties(usrs);
        setFetchFaculties(true);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getFacultyDevelopments = () => {
    axios
      .get(baseUrl + "/faculty-developments", config)
      .then((response) => {
        setFacultyDevelopments(response.data.faculty);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleStatusChange = (obj) => {
    setStatus(obj);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!faculty) {
      fieldName.push("Faculty");
    }
    if (!skillsToDevelop) {
      fieldName.push("Skills to Develop");
    }
    if (!status) {
      fieldName.push("Status");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "add") {
      addFacultyDevelopment();
    } else {
      updateFacultyDevelopment();
    }
  };

  const addFacultyDevelopment = () => {
    const newFacultyDevelopment = {
      faculty_id: faculty.value,
      skills: skillsToDevelop,
      status: status.value,
    };

    axios
      .post(baseUrl + "/faculty-development", newFacultyDevelopment, config)
      .then((response) => {
        toast.success(response.data.message);
        getFacultyDevelopments();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
    clearFields();
  };

  const clearFields = () => {
    setFaculty("");
    setSkillsToDevelop("");
    setStatus("");
    setMode("add");
  };

  const editFacultyDevelopment = (fac) => {
    setFacultyDevelopment(fac);
    setFaculty(faculties.find((option) => option.value === fac.faculty.id));
    setSkillsToDevelop(fac.skills);
    setStatus(statusOptions.find((option) => option.value === fac.status));
    setMode("edit");
  };

  const updateFacultyDevelopment = () => {
    const updatedFacultyDevelopment = {
      faculty_id: faculty.value,
      skills: skillsToDevelop,
      status: status.value,
    };

    axios
      .post(
        baseUrl + "/faculty-development/" + facultyDevelopment.id,
        updatedFacultyDevelopment,
        config
      )
      .then((response) => {
        toast.success(response.data.message);
        getFacultyDevelopments();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
    clearFields();
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Faculty Development
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Faculty</label>
                <Select
                  options={faculties}
                  value={faculty}
                  onChange={(obj) => setFaculty(obj)}
                  placeholder="Select Faculty"
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Skills to Develop
                </label>
                <input
                  type="text"
                  name="Skills to Develop"
                  className="form-control"
                  value={skillsToDevelop}
                  onChange={(e) => setSkillsToDevelop(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Status</label>
                <Select
                  isSearchable={false}
                  options={statusOptions}
                  onChange={handleStatusChange}
                  value={status}
                  placeholder="Select Status"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w250 rounded-lg d-inline-block"
                onClick={handleSubmit}
              >
                {mode === "add" ? "Add" : "Update"} Faculty Development
              </Link>
            </div>
          </div>
        </form>
      </div>
      {facultyDevelopments?.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">ID</th>
                <th className="bg-current text-white">Faculty Name</th>
                <th className="bg-current text-white">Faculty Role</th>
                <th className="bg-current text-white">Skills to Develop</th>
                <th className="bg-current text-white">Status</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {facultyDevelopments.map((facultyDevelop) => (
                <tr key={facultyDevelop.id}>
                  <td>{facultyDevelop.id}</td>
                  <td>{facultyDevelop.faculty.name}</td>
                  <td className="text-capitalize">
                    {facultyDevelop.faculty.role}
                  </td>
                  <td>{facultyDevelop.skills}</td>
                  <td className="text-capitalize">{facultyDevelop.status}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current"
                      style={{ cursor: "pointer" }}
                      onClick={() => editFacultyDevelopment(facultyDevelop)}
                      onKeyDown={(e) => {}}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default FacultyDevelopment;

import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

function Home() {
  const options = {
    items: 4,
    responsive: {
      0: {
        items: 1,
      },
      375: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1024: {
        items: 2,
      },
      1440: {
        items: 3,
      },
    },
  };

  return (
    <div>
      <div className="banner-wrapper bg-after-fluid">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 order-lg-2">
              <img
                src="images/background.png"
                alt="background"
                className="pt-lg--10 w-100 aos-init"
                data-aos="zoom-in"
                data-aos-delay={200}
                data-aos-duration={500}
              />
            </div>
            <div className="col-xl-6 col-lg-7 order-lg-1 pt-lg--10 pb-lg--10 xl-p-5">
              <h2
                className="display3-size display2-md-size fw-700 aos-init"
                data-aos="fade-up"
                data-aos-delay={100}
                data-aos-duration={500}
              >
                Navigate through our Courses
              </h2>
              <h4
                className="text-grey-500 font-xssss fw-500 ml-1 aos-init lh-24"
                data-aos="fade-up"
                data-aos-delay={200}
                data-aos-duration={500}
              >
                Find the right course for you
              </h4>
              <div className="row form-group">
                <div className="col-lg-4">
                  <Link
                    to="/courses"
                    className="w-100 d-block btn bg-current text-white font-xssss fw-600 ls-3 style1-input p-0 border-0 text-uppercase mont-font"
                  >
                    Our Courses
                  </Link>
                </div>
              </div>
              <h4 className="text-grey-500 font-xssss fw-500 ml-1 lh-24">
                <b className="text-grey-800">Popular Courses :</b> React, PHP,
                HTML, CSS, Bootstrap, MySQL, Laravel
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="how-to-work pt-lg--5 pb-lg--7">
        <div className="container">
          <div className="row justify-content-center">
            <div className="page-title style1 col-xl-6 col-lg-8 col-md-10 text-center mb-5">
              <h2 className="text-grey-900 fw-700 display1-size display2-md-size pb-3 mb-0 d-block">
                Our Objectives
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="card p-4 border-0 text-center d-block arrow-right">
                <svg
                  className="position-absolute top-0"
                  style={{ marginLeft: "-44px", opacity: "0.2" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="125.062"
                  height="88.62"
                  viewBox="0 0 125.062 88.62"
                >
                  <path
                    style={{ fill: "#ffb09f" }}
                    d="M488.806,2544.02s35.988-16.17,53.518-7.45S565,2541.44,574,2549s18.09,19.21,14.009,41.12c-3.62,19.44-25.466,15.87-37.2,27.79-10.557,10.72-68.616,1.88-74.4-12.88-6.841-17.45-13.114-17.84-12.406-34.03C464.452,2560.66,475.315,2554.71,488.806,2544.02Z"
                    transform="translate(-463.938 -2534)"
                  />
                </svg>
                <i className="feather-layers display1-size position-relative z-index text-warning" />
                <h2 className="font-md fw-700 text-grey-900 mt-5 mb-3">
                  Knowledge Enrichment
                </h2>
              </div>
            </div>
            <div className="col-lg-4 arrow-right">
              <div className="card p-4 border-0 text-center d-block">
                <svg
                  className="position-absolute top-0"
                  style={{ marginLeft: "-44px", opacity: "0.2" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="125.062"
                  height="88.62"
                  viewBox="0 0 125.062 88.62"
                >
                  <path
                    style={{ fill: "#ad78ef" }}
                    d="M488.806,2544.02s35.988-16.17,53.518-7.45S565,2541.44,574,2549s18.09,19.21,14.009,41.12c-3.62,19.44-25.466,15.87-37.2,27.79-10.557,10.72-68.616,1.88-74.4-12.88-6.841-17.45-13.114-17.84-12.406-34.03C464.452,2560.66,475.315,2554.71,488.806,2544.02Z"
                    transform="translate(-463.938 -2534)"
                  />
                </svg>
                <i className="feather-lock display1-size position-relative z-index text-secondary" />
                <h2 className="font-md fw-700 text-grey-900 mt-5 mb-3">
                  Secure Learning
                </h2>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card p-4 border-0 text-center d-block">
                <svg
                  className="position-absolute top-0"
                  style={{ marginLeft: "-44px", opacity: "0.2" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="125.062"
                  height="88.62"
                  viewBox="0 0 125.062 88.62"
                >
                  <path
                    style={{ fill: "#3ed2a7" }}
                    d="M488.806,2544.02s35.988-16.17,53.518-7.45S565,2541.44,574,2549s18.09,19.21,14.009,41.12c-3.62,19.44-25.466,15.87-37.2,27.79-10.557,10.72-68.616,1.88-74.4-12.88-6.841-17.45-13.114-17.84-12.406-34.03C464.452,2560.66,475.315,2554.71,488.806,2544.02Z"
                    transform="translate(-463.938 -2534)"
                  />
                </svg>
                <i className="feather-box display1-size position-relative z-index text-success" />
                <h2 className="font-md fw-700 text-grey-900 mt-5 mb-3">
                  24 x 7 Support
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how-to-work pb-lg--7">
        <div className="container">
          <div className="row justify-content-center">
            <div className="page-title style1 col-xl-6 col-lg-8 col-md-10 text-center mb-5">
              <h2 className="text-grey-900 fw-700 display1-size display2-md-size pb-3 mb-0 d-block">
                Our Popular Courses
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
              <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1">
                <div className="card-body pt-0">
                  <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                    <a
                      href="course-details.html"
                      className="text-dark text-grey-900"
                    >
                      The Data Science Course Complete Data Science
                    </a>
                  </h4>
                  <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                    23 Lesson
                    <span className="float-right mt-1 font-xssss text-grey-500">
                      87%
                    </span>
                  </h6>
                  <div className="progress mt-4 h5 w-100 mb-3">
                    <div
                      className="progress-bar bg-info"
                      role="progressbar"
                      aria-valuenow={73}
                      aria-valuemin={0}
                      aria-valuemax={73}
                      style={{ width: "73%" }}
                    />
                  </div>
                  <div className="star float-left text-left mb-0">
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star-disable.png"
                      alt="star"
                      className="w10 float-left mr-2"
                    />
                  </div>
                  <p className="review-link mt-0 font-xssss float-right mb-2 fw-500 text-grey-500 lh-3">
                    2 customer review
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
              <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1">
                <div className="card-body pt-0">
                  <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                    <a
                      href="course-details.html"
                      className="text-dark text-grey-900"
                    >
                      Complete Python Bootcamp From Zero to Hero in Python
                    </a>
                  </h4>
                  <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                    24 Lesson
                    <span className="float-right mt-1 font-xssss text-grey-500">
                      65%
                    </span>
                  </h6>
                  <div className="progress mt-4 h5 w-100 mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-valuenow={96}
                      aria-valuemin={0}
                      aria-valuemax={96}
                      style={{ width: "96%" }}
                    />
                  </div>
                  <div className="star float-left text-left mb-0">
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star-disable.png"
                      alt="star"
                      className="w10 float-left mr-2"
                    />
                  </div>
                  <p className="review-link mt-0 font-xssss float-right mb-2 fw-500 text-grey-500 lh-3">
                    2 customer review
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
              <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1">
                <div className="card-body pt-0">
                  <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                    <a
                      href="course-details.html"
                      className="text-dark text-grey-900"
                    >
                      Fundamentals for Scrum Master and Agile Projects
                    </a>
                  </h4>
                  <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                    32 Lesson
                    <span className="float-right mt-1 font-xssss text-grey-500">
                      75%
                    </span>
                  </h6>
                  <div className="progress mt-4 h5 w-100 mb-3">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      aria-valuenow={75}
                      aria-valuemin={0}
                      aria-valuemax={75}
                      style={{ width: "75%" }}
                    />
                  </div>
                  <div className="star float-left text-left mb-0">
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star-disable.png"
                      alt="star"
                      className="w10 float-left mr-2"
                    />
                  </div>
                  <p className="review-link mt-0 font-xssss float-right mb-2 fw-500 text-grey-500 lh-3">
                    2 customer review
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
              <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1">
                <div className="card-body pt-0">
                  <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                    <a
                      href="course-details.html"
                      className="text-dark text-grey-900"
                    >
                      Complete Python Bootcamp From Zero to Hero in Python
                    </a>
                  </h4>
                  <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                    32 Lesson
                    <span className="float-right mt-1 font-xssss text-grey-500">
                      96%
                    </span>
                  </h6>
                  <div className="progress mt-4 h5 w-100 mb-3">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      aria-valuenow={65}
                      aria-valuemin={0}
                      aria-valuemax={65}
                      style={{ width: "65%" }}
                    />
                  </div>
                  <div className="star float-left text-left mb-0">
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star-disable.png"
                      alt="star"
                      className="w10 float-left mr-2"
                    />
                  </div>
                  <p className="review-link mt-0 font-xssss float-right mb-2 fw-500 text-grey-500 lh-3">
                    2 customer review
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
              <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1">
                <div className="card-body pt-0">
                  <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                    <a
                      href="course-details.html"
                      className="text-dark text-grey-900"
                    >
                      Complete Python Bootcamp From Zero to Hero in Python
                    </a>
                  </h4>
                  <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                    24 Lesson
                    <span className="float-right mt-1 font-xssss text-grey-500">
                      73%
                    </span>
                  </h6>
                  <div className="progress mt-4 h5 w-100 mb-3">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      aria-valuenow={87}
                      aria-valuemin={0}
                      aria-valuemax={87}
                      style={{ width: "87%" }}
                    />
                  </div>
                  <div className="star float-left text-left mb-0">
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star-disable.png"
                      alt="star"
                      className="w10 float-left mr-2"
                    />
                  </div>
                  <p className="review-link mt-0 font-xssss float-right mb-2 fw-500 text-grey-500 lh-3">
                    2 customer review
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
              <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1">
                <div className="card-body pt-0">
                  <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                    <a
                      href="course-details.html"
                      className="text-dark text-grey-900"
                    >
                      Java Programming Masterclass for Developers
                    </a>
                  </h4>
                  <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                    24 Lesson
                    <span className="float-right mt-1 font-xssss text-grey-500">
                      73%
                    </span>
                  </h6>
                  <div className="progress mt-4 h5 w-100 mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      aria-valuenow={96}
                      aria-valuemin={0}
                      aria-valuemax={96}
                      style={{ width: "96%" }}
                    />
                  </div>
                  <div className="star float-left text-left mb-0">
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star.png"
                      alt="star"
                      className="w10 mr-1 float-left"
                    />
                    <img
                      src="images/star-disable.png"
                      alt="star"
                      className="w10 float-left mr-2"
                    />
                  </div>
                  <p className="review-link mt-0 font-xssss float-right mb-2 fw-500 text-grey-500 lh-3">
                    2 customer review
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feedback-wrapper pb-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-left mb-5 pb-0">
              <h2 className="text-grey-800 fw-700 display1-size display2-md-size lh-2">
                Students love what we do
              </h2>
            </div>
            <div className="col-lg-12">
              <OwlCarousel
                className="owl-carousel category-card owl-theme overflow-hidden overflow-visible-xl nav-none feedback-slider overflow-visible dot-none right-nav pb-4"
                {...options}
                style={{ alignItems: "stretch" }}
              >
                <div className="item text-center mr-3">
                  <div className="card w-100 p-5 text-left border-0 shadow-xss rounded-lg">
                    <div className="card-body pl-0 pt-0">
                      <h4 className="text-grey-900 fw-700 font-xsss mt-0 pt-1">
                        Ellen Waelchi
                      </h4>
                      <h5 className="font-xssss fw-500 mb-1 text-grey-500">
                        Future Mobility Developer
                      </h5>
                    </div>
                    <p className="font-xsss fw-400 text-grey-500 lh-28 mt-0 mb-0">
                      Enrolling in the online Masters in Computer Science
                      program was a game-changer. The courses were
                      well-structured, and the professors were knowledgeable.
                      The supportive online community was the best part. Thanks
                      to this program, I've gained not only a degree but also a
                      network of like-minded individuals. Highly recommend!
                    </p>
                    <div className="star d-block w-100 text-right mt-4 mb-0">
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="item text-center mr-3">
                  <div className="card w-100 p-5 text-left border-0 shadow-xss rounded-lg">
                    <div className="card-body pl-0 pt-0">
                      <h4 className="text-grey-900 fw-700 font-xsss mt-0 pt-1">
                        Cameron Dooley
                      </h4>
                      <h5 className="font-xssss fw-500 mb-1 text-grey-500">
                        Senior Solutions Administrator
                      </h5>
                    </div>
                    <p className="font-xsss fw-400 text-grey-500 lh-28 mt-0 mb-0">
                      I'm thrilled with my decision to pursue an online Master's
                      in Computer Science. The user-friendly online platform and
                      top-notch course content were impressive. The real-world
                      focus of the curriculum helped me excel in my career. The
                      accessible instructors and academic community made it
                      possible to balance work and education.
                    </p>
                    <div className="star d-block w-100 text-right mt-4 mb-0">
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="item text-center mr-3">
                  <div className="card w-100 p-5 text-left border-0 shadow-xss rounded-lg">
                    <div className="card-body pl-0 pt-0">
                      <h4 className="text-grey-900 fw-700 font-xsss mt-0 pt-1">
                        Lucie Dicki
                      </h4>
                      <h5 className="font-xssss fw-500 mb-1 text-grey-500">
                        Dynamic Accounts Supervisor
                      </h5>
                    </div>
                    <p className="font-xsss fw-400 text-grey-500 lh-28 mt-0 mb-0">
                      Initially skeptical about online learning, it exceeded my
                      expectations. The Master's in Computer Science program was
                      rigorous and rewarding. Virtual labs and interactive
                      assignments provided hands-on experience. Discussion
                      forums encouraged collaboration, and professors were
                      responsive crediting for my career growth.
                    </p>
                    <div className="star d-block w-100 text-right mt-4 mb-0">
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star-disable.png"
                        alt="star"
                        className="w15 float-left mr-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="item text-center mr-3">
                  <div className="card w-100 p-5 text-left border-0 shadow-xss rounded-lg">
                    <div className="card-body pl-0 pt-0">
                      <h4 className="text-grey-900 fw-700 font-xsss mt-0 pt-1">
                        Aaliyah Harris
                      </h4>
                      <h5 className="font-xssss fw-500 mb-1 text-grey-500">
                        Global Optimization Consultant
                      </h5>
                    </div>
                    <p className="font-xsss fw-400 text-grey-500 lh-28 mt-0 mb-0">
                      Being from the UK, I hesitated to pursue an online
                      Master's degree abroad. However, the experience was
                      seamless. The global perspective and diverse student body
                      enriched my learning. Up-to-date course materials prepared
                      me well for the tech industry. I've recommended this
                      program to colleagues and am grateful for career
                      advancement.
                    </p>
                    <div className="star d-block w-100 text-right mt-4 mb-0">
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                      <img
                        src="images/star.png"
                        alt="star"
                        className="w15 mr-1 float-left mr-2"
                      />
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      <div className="subscribe-wrapper pt-5 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="card w-100 p-lg-5 p-4 rounded-xxl border-0"
                style={{ background: "#faece3" }}
              >
                <div className="row justify-content-center">
                  <div className="col-lg-10 text-center">
                    <h2 className="fw-700 text-grey-900 display2-size display2-md-size lh-3 mb-3">
                      Subscribe up to our newsletter
                    </h2>
                  </div>
                  <div className="col-lg-6 text-center mt-md-4 mb-3">
                    <div className="form-group icon-right-input style2-input mb-0">
                      <input
                        type="text"
                        placeholder="Enter Email Address"
                        className="form-control style2 rounded-xl bg-white border-0 font-xsss fw-500 pl-3 mont-font"
                      />
                      <i className="feather-mail text-primary font-lg m-1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

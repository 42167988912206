import { Link } from "react-router-dom";

export default function Courses() {
  return (
    <div className="blog-page pt-lg--7 pb-lg--7 pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <div
              className="card rounded-xxl p-lg--5 border-0 bg-no-repeat"
              style={{ backgroundColor: "#e4f7fe" }}
            >
              <div className="card-body w-100 p-4">
                <div className="form-group mt-3 p-3 border-light border p-2 bg-white rounded-lg">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="form-group icon-input mb-0">
                        <i className="ti-search font-xs text-grey-400" />
                        <input
                          type="text"
                          className="style1-input border-0 pl-5 font-xsss mb-0 text-grey-500 fw-500 bg-transparent"
                          placeholder="Search our courses"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group icon-input mb-0">
                        <i className="ti-package font-xs text-grey-400" />
                        <select className="style1-select bg-transparent border-0 pl-5">
                          <option value>All Categories</option>
                          <option value="Frontend">Frontend</option>
                          <option value="HTML">- HTML</option>
                          <option value="CSS">- CSS</option>
                          <option value="Bootstrap">- Bootstrap</option>
                          <option value="Javascript">- Javascript</option>
                          <option value="React">- React</option>
                          <option value="Backend">Backend</option>
                          <option value="PHP">- PHP</option>
                          <option value="MySQL">- MySQL</option>
                          <option value="Laravel">- Laravel</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <Link
                        href="#"
                        className="w-100 d-block btn bg-current text-white font-xssss fw-600 ls-3 style1-input p-0 border-0 text-uppercase"
                      >
                        Search
                      </Link>
                    </div>
                  </div>
                </div>
                <h4 className="text-grey-500 font-xssss fw-500 ml-1 lh-24">
                  <b className="text-grey-800 text-dark">Popular Courses : </b>
                  React, PHP, HTML, CSS, Bootstrap, MySQL, Laravel
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-12 pt-2 mb-4">
            <h2 className="fw-400 font-lg">
              Search <b>Results</b>
              <span className="fw-500 ml-2 text-grey-500 font-xssss">
                ( 11 courses found )
              </span>
            </h2>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
            <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
              <div className="card-body p-0">
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    href="course-details.html"
                    className="text-dark text-grey-900"
                  >
                    Complete Python Bootcamp From Zero to Hero in Python
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  32 Lessons
                </h6>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
            <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
              <div className="card-body p-0">
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    href="course-details.html"
                    className="text-dark text-grey-900"
                  >
                    Complete Python Bootcamp From Zero to Hero in Python
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  24 Lessons
                </h6>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
            <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
              <div className="card-body p-0">
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    href="course-details.html"
                    className="text-dark text-grey-900"
                  >
                    Java Programming Masterclass for Developers
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  14 Lessons
                </h6>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
            <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
              <div className="card-body p-0">
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    href="course-details.html"
                    className="text-dark text-grey-900"
                  >
                    The Data Science Course Complete Data Science
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  23 Lessons
                </h6>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
            <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
              <div className="card-body p-0">
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    href="course-details.html"
                    className="text-dark text-grey-900"
                  >
                    Complete Python Bootcamp From Zero to Hero in Python
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  24 Lessons
                </h6>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
            <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
              <div className="card-body p-0">
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    href="course-details.html"
                    className="text-dark text-grey-900"
                  >
                    Fundamentals for Scrum Master and Agile Projects
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  32 Lessons
                </h6>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
            <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
              <div className="card-body p-0">
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    href="course-details.html"
                    className="text-dark text-grey-900"
                  >
                    Automate the Boring Stuff with Python Programming
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  14 Lessons
                </h6>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
            <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
              <div className="card-body p-0">
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    href="course-details.html"
                    className="text-dark text-grey-900"
                  >
                    The Data Science Course Complete Data Science
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  23 Lessons
                </h6>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
            <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
              <div className="card-body p-0">
                <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                  <Link
                    href="course-details.html"
                    className="text-dark text-grey-900"
                  >
                    Complete Python Bootcamp From Zero to Hero in Python
                  </Link>
                </h4>
                <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                  32 Lessons
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <ul className="pagination justify-content-center d-flex pt-5">
              <li className="page-item m-1">
                <Link
                  className="page-link rounded-lg btn-round-md p-0 fw-600 shadow-xss bg-white text-grey-900 border-0"
                  href="#"
                  tabIndex={-1}
                  aria-disabled="true"
                >
                  <i className="ti-angle-left" />
                </Link>
              </li>
              <li className="page-item m-1">
                <Link
                  className="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss bg-white text-grey-900 border-0"
                  href="#"
                >
                  1
                </Link>
              </li>
              <li className="page-item m-1">
                <Link
                  className="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss bg-primary text-white border-0"
                  href="#"
                >
                  2
                </Link>
              </li>
              <li className="page-item m-1">
                <Link
                  className="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss bg-white text-grey-900 border-0"
                  href="#"
                >
                  3
                </Link>
              </li>
              <li className="page-item m-1">
                <Link
                  className="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss bg-white text-grey-900 border-0"
                  href="#"
                >
                  4
                </Link>
              </li>
              <li className="page-item m-1">
                <Link
                  className="page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss bg-white text-grey-900 border-0"
                  href="#"
                >
                  5
                </Link>
              </li>
              <li className="page-item m-1">
                <Link
                  className="page-link rounded-lg btn-round-md p-0 fw-600 shadow-xss bg-white text-grey-900 border-0"
                  href="#"
                >
                  <i className="ti-angle-right" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";

export default function Header() {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="middle-sidebar-header bg-white">
      <button className="header-menu" />
      <ul className="d-flex ml-auto right-menu-icon">
        <li>
          <Link>
            {/* <span className="dot-count bg-warning" /> */}
            <span className="bg-warning" />
            <i className="feather-bell font-xl text-current" />
            <div className="menu-dropdown">
              <h4 className="fw-700 font-xs mb-4">Notifications</h4>
              <div className="card bg-transparent-card w-100 border-0">
                <h6 className="text-grey-500 fw-500 font-xssss lh-4 text-center">
                  No Notifications
                </h6>
              </div>
              {/* <div className="card bg-transparent-card w-100 border-0 pl-5">
                <img
                  src="/images/user-6.png"
                  alt="user"
                  className="w40 position-absolute left-0"
                />
                <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
                  Madeline Ratke{" "}
                  <span className="text-grey-400 font-xsssss fw-600 float-right mt-1">
                    30 sec
                  </span>
                </h5>
                <h6 className="text-grey-500 fw-500 font-xssss lh-4">
                  Mobile Apps UI Designer is require..
                </h6>
              </div> */}
            </div>
          </Link>
        </li>
        <li>
          <Link to="/dashboard/messages">
            <i className="feather-message-square font-xl text-current" />
          </Link>
        </li>
        <li>
          <Link to="/dashboard/settings">
            <img src={user.image} alt="Profile" className="w40 mt--1" />
          </Link>
        </li>
        <li>
          <Link className="menu-search-icon">
            <i className="feather-search text-grey-900 font-lg" />
          </Link>
        </li>
      </ul>
    </div>
  );
}

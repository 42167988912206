import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import env from "../../../../env";
import axios from "axios";
import Select from "react-select";

function CreateExam(props) {
  const navigate = useNavigate();
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");

  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState("");

  const [mode, setMode] = useState("create");
  const [activeId, setActiveId] = useState(-1);
  const [exams, setExams] = useState([]);
  const [examName, setExamName] = useState("");
  const [examDuration, setExamDuration] = useState("");
  const [status, setStatus] = useState("");

  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [newOptions, setNewOptions] = useState(["", "", "", ""]);
  const [newCorrectAnswer, setNewCorrectAnswer] = useState("");

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  useEffect(() => {
    if (!courses.length) {
      getCourses();
      getAllExams();
    }
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getCourses = () => {
    axios
      .get(baseUrl + "/courses", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let crs = [];
        for (const course of response.data.courses) {
          crs.push({ value: course.id, label: course.name });
        }
        setCourses(crs);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getAllExams = () => {
    axios
      .get(baseUrl + "/exams", config)
      .then((response) => {
        setExams(response.data.exams);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleCourseChange = (id) => {
    axios
      .get(baseUrl + "/course/" + id, config)
      .then((response) => {
        setCourse(id);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = [];

    if (course === "") {
      requiredFields.push("Course");
    }
    if (!examName.length) {
      requiredFields.push("Exam Name");
    }
    if (!examDuration.length) {
      requiredFields.push("Exam Duration");
    }
    if (!status.length) {
      requiredFields.push("Status");
    }
    if (!questions.length) {
      requiredFields.push("Question");
    }
    if (questions.some((question) => !question.options.length)) {
      requiredFields.push("Options");
    }
    if (questions.some((question) => !question.correctAnswer.trim())) {
      requiredFields.push("Correct Answer");
    }

    if (requiredFields.length > 0) {
      for (const field of requiredFields) {
        toast.error(`${field} is Required!`);
      }
      return;
    }

    if (mode === "create") {
      createExam();
    } else if (mode === "edit") {
      updateExam();
    }
  };

  const createExam = () => {
    const newExam = {
      course_id: course,
      name: examName,
      duration: examDuration,
      questions: questions,
      status: status,
    };

    axios
      .post(baseUrl + "/exam", newExam, config)
      .then((res) => {
        toast.success(`${res.data.message}`);
        getAllExams();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });

    clearFields();
  };

  const addQuestion = () => {
    if (!newQuestion.trim()) {
      toast.error("Question is Required!");
      return;
    }
    if (!newOptions.some((option) => option.trim())) {
      toast.error("Options are Required!");
      return;
    }
    if (!newCorrectAnswer.trim()) {
      toast.error("Correct Answer is Required!");
      return;
    }
    if (
      !newOptions.some((option) => option.trim() === newCorrectAnswer.trim())
    ) {
      toast.error("Correct Answer should be one of the options!");
      return;
    }

    setQuestions((prevQuestions) => [
      ...prevQuestions,
      {
        question: newQuestion,
        options: newOptions,
        correctAnswer: newCorrectAnswer,
      },
    ]);
    setNewQuestion("");
    setNewOptions(["", "", "", ""]);
    setNewCorrectAnswer("");
  };

  const clearFields = () => {
    setCourse("");
    setExamName("");
    setExamDuration("");
    setStatus("");
    setNewQuestion("");
    setNewOptions(["", "", "", ""]);
    setNewCorrectAnswer("");
    setQuestions([]);
  };

  const editExam = (id) => {
    setMode("edit");
    setActiveId(id);
    const exam = exams.find((exam) => exam.id === id);
    setCourse(exam.course_id);
    setExamName(exam.name);
    setExamDuration(exam.duration);
    setStatus(exam.status);
    setQuestions(exam.questions);
  };

  const updateExam = () => {
    axios
      .post(
        baseUrl + "/exam/" + activeId,
        {
          course_id: course,
          name: examName,
          duration: examDuration,
          questions: questions,
          status: status,
        },
        config
      )
      .then((res) => {
        toast.success(res.data.message);
        getAllExams();
        setMode("create");
        setActiveId(-1);
        clearFields();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption.value);
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Exam Creation
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Course</label>
                <Select
                  isSearchable={false}
                  options={courses}
                  value={courses.find((option) => option.value === course)}
                  onChange={(e) => (e ? handleCourseChange(e.value) : "")}
                  placeholder="Select a Course"
                />
              </div>
            </div>
          </div>
        </form>
        <form>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Exam Name</label>
                <input
                  type="text"
                  name="exam-name"
                  className="form-control"
                  onChange={(e) => setExamName(e.target.value)}
                  value={examName}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Exam Duration (in Minutes)
                </label>
                <input
                  type="number"
                  min="1"
                  name="exam-duration"
                  className="form-control"
                  onChange={(e) => setExamDuration(e.target.value)}
                  value={examDuration}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Status</label>
                <Select
                  options={statusOptions}
                  value={statusOptions.find(
                    (option) => option.value === status
                  )}
                  onChange={handleStatusChange}
                  placeholder="Exam Status"
                />
              </div>
            </div>
          </div>
        </form>
        {questions.map((question, index) => (
          <div key={index} className="mb-4">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">
                Question {index + 1}
              </label>
              <input
                type="text"
                name={`new-question-${index}`}
                className="form-control"
                value={question.question}
                disabled
              />
            </div>
            <label className="mont-font fw-600 font-xsss">Options</label>
            {question.options.map((option, optionIndex) => (
              <div key={optionIndex} className="form-group">
                <input
                  type="text"
                  name={`option-${index}-${optionIndex}`}
                  className="form-control"
                  value={option}
                  disabled
                />
              </div>
            ))}
            <label className="mont-font fw-600 font-xsss">Correct Answer</label>
            <input
              type="text"
              name={`correct-answer-${index}`}
              className="form-control"
              value={question.correctAnswer}
              disabled
            />
          </div>
        ))}
        <div className="mb-4">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">
              Question {questions.length + 1}
            </label>
            <input
              type="text"
              name="new-question"
              className="form-control"
              onChange={(e) => setNewQuestion(e.target.value)}
              value={newQuestion}
            />
          </div>
          <label className="mont-font fw-600 font-xsss">Options</label>
          {newOptions.map((option, index) => (
            <div key={index} className="form-group">
              <input
                type="text"
                name={`option-${index}`}
                className="form-control"
                value={option}
                onChange={(e) => {
                  const updatedOptions = [...newOptions];
                  updatedOptions[index] = e.target.value;
                  setNewOptions(updatedOptions);
                }}
              />
            </div>
          ))}
          <label className="mont-font fw-600 font-xsss">Correct Answer</label>
          <input
            type="text"
            name="correct-answer"
            className="form-control"
            onChange={(e) => setNewCorrectAnswer(e.target.value)}
            value={newCorrectAnswer}
          />
          <button onClick={addQuestion} className="btn btn-primary mt-4">
            Add Question
          </button>
        </div>
        <div className="col-lg-12 d-flex align-items-end justify-content-end">
          <Link
            className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            {mode === "create" ? "Create" : "Update"} Exam
          </Link>
        </div>
      </div>
      {exams.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Exam ID</th>
                <th className="bg-current text-white">Course Name</th>
                <th className="bg-current text-white">Exam Name</th>
                <th className="bg-current text-white">
                  Exam Duration (in Minutes)
                </th>
                <th className="bg-current text-white">Questions</th>
                <th className="bg-current text-white">Status</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {exams.map((exam) => (
                <tr key={exam.id}>
                  <td>{exam.id}</td>
                  <td>{exam.course.name}</td>
                  <td>{exam.name}</td>
                  <td>{exam.duration}</td>
                  <td>
                    <ul>
                      {exam.questions.map((question, index) => (
                        <li key={index}>
                          <strong>Question {index + 1}:</strong>{" "}
                          {question.question}
                          <br />
                          <strong>Options:</strong>{" "}
                          {question.options.join(", ")}
                          <br />
                          <strong>Correct Answer:</strong>{" "}
                          {question.correctAnswer}
                          <br />
                          <br />
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="text-capitalize">{exam.status}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current"
                      style={{ cursor: "pointer" }}
                      onClick={() => editExam(exam.id)}
                      onKeyDown={() => {}}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default CreateExam;

import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'

// ----------------------------------------------------------------------

const defaultMiddleware = () => getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  })

const store = configureStore({
  reducer: rootReducer,
  middleware: defaultMiddleware(),
})

const { dispatch } = store

export { store, dispatch }

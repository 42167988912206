import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import env from "../../../../env";
import axios from "axios";

function QSCompliance() {
  const navigate = useNavigate();
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let isError = false;

  const [mode, setMode] = useState("add");

  const [fetchCompliances, setFetchCompliances] = useState(false);
  const [compliances, setCompliances] = useState([]);
  const [complianceId, setComplianceId] = useState("");
  const [complianceName, setComplianceName] = useState("");
  const [complianceCode, setComplianceCode] = useState("");
  const [complianceDescription, setComplianceDescription] = useState("");
  const statusOptions = [
    { value: "todo", label: "Todo" },
    { value: "in-progress", label: "In Progress" },
    { value: "done", label: "Done" },
  ];
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (!fetchCompliances) {
      getCompliances();
    }
  });

  const getCompliances = () => {
    axios
      .get(baseUrl + "/compliances", config)
      .then((response) => {
        setCompliances(response.data.compliances);
        setFetchCompliances(true);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleStatusChange = (obj) => {
    setStatus(obj);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!complianceCode) {
      fieldName.push("Compliance Code");
    }
    if (!complianceName) {
      fieldName.push("Compliance Name");
    }
    if (!complianceDescription) {
      fieldName.push("Compliance Description");
    }
    if (!status) {
      fieldName.push("Status");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "add") {
      addCompliance();
    } else {
      updateCompliance();
    }
  };
  const addCompliance = () => {
    const newCompliance = {
      code: complianceCode,
      name: complianceName,
      description: complianceDescription,
      status: status.value,
    };

    axios
      .post(baseUrl + "/compliance", newCompliance, config)
      .then((res) => {
        toast.success(res.data.message);
        getCompliances();
        clearFields();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };
  const clearFields = () => {
    setComplianceId("");
    setComplianceCode("");
    setComplianceDescription("");
    setStatus("");
  };
  const editCompliance = (compliance) => {
    setMode("edit");
    setComplianceId(compliance.id);
    setComplianceCode(compliance.code);
    setComplianceName(compliance.name);
    setComplianceDescription(compliance.description);
    statusOptions.forEach((option) => {
      if (option.value === compliance.status) {
        setStatus(option);
      }
    });
  };

  const updateCompliance = () => {
    const updatedCompliance = {
      code: complianceCode,
      name: complianceName,
      description: complianceDescription,
      status: status.value,
    };

    axios
      .post(baseUrl + "/compliance/" + complianceId, updatedCompliance, config)
      .then((res) => {
        toast.success(res.data.message);
        getCompliances();
        clearFields();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          QS Compliance
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Compliance Code
                </label>
                <input
                  type="text"
                  name="compliance-code"
                  className="form-control"
                  onChange={(e) => setComplianceCode(e.target.value)}
                  value={complianceCode}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Compliance Name
                </label>
                <input
                  type="text"
                  name="compliance-name"
                  className="form-control"
                  onChange={(e) => setComplianceName(e.target.value)}
                  value={complianceName}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Compliance Description
                </label>
                <textarea
                  name="compliance-descriptiom"
                  className="form-control"
                  onChange={(e) => setComplianceDescription(e.target.value)}
                  value={complianceDescription}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Status</label>
                <Select
                  isSearchable={false}
                  options={statusOptions}
                  onChange={handleStatusChange}
                  value={status}
                  placeholder="Select Status"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={handleSubmit}
              >
                {mode === "add" ? "Add" : "Update"} Compliance
              </Link>
            </div>
          </div>
        </form>
      </div>
      {compliances?.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Compliance ID</th>
                <th className="bg-current text-white">Compliance Code</th>
                <th className="bg-current text-white">Compliance Name</th>
                <th className="bg-current text-white">
                  Compliance Description
                </th>
                <th className="bg-current text-white">Status</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {compliances.map((compliance) => (
                <tr key={compliance.id}>
                  <td>{compliance.id}</td>
                  <td>{compliance.code}</td>
                  <td>{compliance.name}</td>
                  <td>{compliance.description}</td>
                  <td className="text-capitalize">
                    {statusOptions.map((option) =>
                      option.value === compliance.status ? option.label : ""
                    )}
                  </td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current"
                      style={{ cursor: "pointer" }}
                      onClick={() => editCompliance(compliance)}
                      onKeyDown={(e) => {}}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default QSCompliance;

import { combineReducers } from 'redux'

// slices
import app from './slices/app'

const rootReducer = combineReducers({
  app,
})

export default rootReducer

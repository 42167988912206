import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function AssessmentTools() {
  const navigate = useNavigate();
  let isError = false;

  const [mode, setMode] = useState("add");
  const [id, setId] = useState(1);
  const [activeId, setActiveId] = useState(-1);
  const [assessmentTools, setAssessmentTools] = useState([]);
  const [assessmentToolName, setAssessmentToolName] = useState("");
  const [assessmentType, setAssessmentType] = useState("");
  const [assessmentResult, setAssessmentResult] = useState("");
  const [statusOptions] = useState([
    { value: "Todo", label: "Todo" },
    { value: "In Progress", label: "In Progress" },
    { value: "Done", label: "Done" },
  ]);
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusSelection = (e) => {
    setSelectedStatus(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!assessmentToolName) {
      fieldName.push("Assessment Tool Name");
    }
    if (!assessmentResult) {
      fieldName.push("Assessment Result");
    }
    if (!selectedStatus) {
      fieldName.push("Selected Status");
    }
    if (!assessmentType) {
      fieldName.push("Assessment Type");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "add") {
      addAssessmentTool();
    } else {
      updateAssessmentTool();
    }
  };
  const addAssessmentTool = () => {
    const newCompliance = {
      id: id,
      assessmentToolName: assessmentToolName,
      assessmentResult: assessmentResult,
      assessmentType: assessmentType,
      selectedStatus: selectedStatus,
    };
    setAssessmentTools((prevAssessmentTools) => [
      ...prevAssessmentTools,
      newCompliance,
    ]);
    clearFields();
    setId(id + 1);
  };
  const clearFields = () => {
    setAssessmentToolName("");
    setAssessmentResult("");
    setAssessmentType("");
    setSelectedStatus("");
  };
  const editAssessmentTool = (id) => {
    setMode("edit");
    setActiveId(id);
    const assessmentTool = assessmentTools.find(
      (assessmentTool) => assessmentTool.id === id
    );
    setAssessmentToolName(assessmentTool.assessmentToolName);
    setAssessmentResult(assessmentTool.assessmentResult);
    setAssessmentType(assessmentTool.assessmentType);
    setSelectedStatus(assessmentTool.selectedStatus);
  };
  const deleteAssessmentTool = (id) => {
    const newAssessmentTools = assessmentTools.filter(
      (assessmentTool) => assessmentTool.id !== id
    );
    setAssessmentTools(newAssessmentTools);
  };
  const updateAssessmentTool = () => {
    const assessmentTool = assessmentTools.find(
      (assessmentTool) => assessmentTool.id === activeId
    );
    assessmentTool.assessmentToolName = assessmentToolName;
    assessmentTool.assessmentResult = assessmentResult;
    assessmentTool.assessmentType = assessmentType;
    assessmentTool.selectedStatus = selectedStatus;
    clearFields();
    setMode("add");
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Assessment Tools
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Assessment Tool Name
                </label>
                <input
                  type="text"
                  name="assesment tool name"
                  className="form-control"
                  onChange={(e) => setAssessmentToolName(e.target.value)}
                  value={assessmentToolName}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Assessment Type
                </label>
                <input
                  type="text"
                  name="assessment-type"
                  className="form-control"
                  onChange={(e) => setAssessmentType(e.target.value)}
                  value={assessmentType}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Assessment Result
                </label>
                <input
                  type="text"
                  name="assessment-result"
                  className="form-control"
                  onChange={(e) => setAssessmentResult(e.target.value)}
                  value={assessmentResult}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">&nbsp;</label>
                <select
                  className="form-control"
                  name="status"
                  id="status"
                  value={selectedStatus}
                  onChange={handleStatusSelection}
                >
                  <option value="">Status</option>
                  {statusOptions.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={handleSubmit}
              >
                {mode === "add" ? "Add" : "Edit"} Assessment Tool
              </Link>
            </div>
          </div>
        </form>
      </div>
      {assessmentTools.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Assessment Tool ID</th>
                <th className="bg-current text-white">Assessment Tool Name</th>
                <th className="bg-current text-white">Assessment Type</th>
                <th className="bg-current text-white">Assessment Result</th>
                <th className="bg-current text-white">Status</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {assessmentTools.map((assessment) => (
                <tr key={assessment.id}>
                  <td>{assessment.id}</td>
                  <td>{assessment.assessmentToolName}</td>
                  <td>{assessment.assessmentType}</td>
                  <td>{assessment.assessmentResult}</td>
                  <td>{assessment.selectedStatus}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editAssessmentTool(assessment.id)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteAssessmentTool(assessment.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default AssessmentTools;

import { useState } from "react";
import { Link } from "react-router-dom";
import env from "../../../../env";
import axios from "axios";
import { toast } from "react-toastify";

export default function Password() {
  const baseUrl = env.BASE_URL;

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    axios
      .post(
        baseUrl + "/change-password",
        {
          current_password: oldPassword,
          new_password: password,
        },
        {
          headers,
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        clearFields();
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const clearFields = () => {
    setOldPassword("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <div>
      <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-lg">
        <Link to="/dashboard/settings" className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Change Password
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-12 mb-3">
              <div className="form-gorup">
                <label className="mont-font fw-600 font-xssss">
                  Current Password
                </label>
                <input
                  type="password"
                  name="comment-name"
                  className="form-control"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-12 mb-3">
              <div className="form-gorup">
                <label className="mont-font fw-600 font-xssss">
                  Change Password
                </label>
                <input
                  type="password"
                  name="comment-name"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-3">
              <div className="form-gorup">
                <label className="mont-font fw-600 font-xssss">
                  Confirm Change Password
                </label>
                <input
                  type="password"
                  name="comment-name"
                  className="form-control"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-0">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={handleSubmit}
              >
                Save
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

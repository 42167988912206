import ApexCharts from "react-apexcharts";
import { useEffect, useState } from "react";
import env from "../../../../env";
import axios from "axios";
import { toast } from "react-toastify";

export default function Analytics() {
  const borderColor = "#E4E4E4";

  const usersOptions = setBarChartOptions(borderColor);
  const coursesOptions = setBarChartOptions(borderColor);
  const classesOptions = setBarChartOptions(borderColor);
  const examsOptions = setBarChartOptions(borderColor);

  function setBarChartOptions(color) {
    return {
      chart: {
        id: "basic-bar",
        type: "bar",
        height: "50%",
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May"],
        axisBorder: {
          show: false,
          color: color,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      grid: {
        borderColor: color,
        strokeDashArray: 0,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "80%",
        },
      },
      states: {
        hover: {
          filter: {
            type: "darken",
            value: 0.9,
          },
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    };
  }

  const usersSeries = [
    {
      name: "Users",
      data: Array.from({ length: 5 }, () => Math.floor(Math.random() * 50) + 1),
    },
  ];
  const coursesSeries = [
    {
      name: "Courses",
      data: Array.from({ length: 5 }, () => Math.floor(Math.random() * 50) + 1),
    },
  ];
  const classesSeries = [
    {
      name: "Classes",
      data: Array.from({ length: 5 }, () => Math.floor(Math.random() * 50) + 1),
    },
  ];
  const examsSeries = [
    {
      name: "Exams",
      data: Array.from({ length: 5 }, () => Math.floor(Math.random() * 50) + 1),
    },
  ];

  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [analytics, setAnalytics] = useState(null);

  useEffect(() => {
    if (!analytics) {
      getAnalytics();
    }
  });

  const getAnalytics = () => {
    axios
      .get(baseUrl + "/analytics", config)
      .then((response) => {
        setAnalytics(response.data);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  return (
    <div className="card w-100 border-0 bg-transparent p-0 mb-4">
      <div className="card-body w-100 border-0 mb-0 rounded-lg p-0">
        <div className="row">
          <div className="col-xl-3 col-lg-12">
            <div className="card w-100 border-0 rounded-lg bg-white shadow-xs overflow-hidden">
              <div className="card-body p-4 rounded-lg">
                <div className="row">
                  <div className="col-7">
                    <h2 className="text-grey-900 fw-700 display1-size mt-2 mb-2 ls-3 lh-1">
                      {analytics?.users || "-"}
                    </h2>
                    <h4 className="fw-700 text-grey-500 font-xsssss ls-3 text-uppercase mb-0 mt-0">
                      Users
                    </h4>
                  </div>
                  <div className="col-5 text-left">
                    <ApexCharts
                      options={usersOptions}
                      series={usersSeries}
                      type="bar"
                      height={"50%"}
                      width={"100%"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-12">
            <div className="card w-100 border-0 rounded-lg bg-white shadow-xs overflow-hidden">
              <div className="card-body p-4 rounded-lg">
                <div className="row">
                  <div className="col-7">
                    <h2 className="text-grey-900 fw-700 display1-size mt-2 mb-2 ls-3 lh-1">
                      {analytics?.courses || "-"}
                    </h2>
                    <h4 className="fw-700 text-grey-500 font-xsssss ls-3 text-uppercase mb-0 mt-0">
                      Courses
                    </h4>
                  </div>
                  <div className="col-5 text-left">
                    <ApexCharts
                      options={coursesOptions}
                      series={coursesSeries}
                      type="bar"
                      height={"50%"}
                      width={"100%"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-12">
            <div className="card w-100 border-0 rounded-lg bg-white shadow-xs overflow-hidden">
              <div className="card-body p-4 rounded-lg">
                <div className="row">
                  <div className="col-7">
                    <h2 className="text-grey-900 fw-700 display1-size mt-2 mb-2 ls-3 lh-1">
                      {analytics?.classes || "-"}
                    </h2>
                    <h4 className="fw-700 text-grey-500 font-xsssss ls-3 text-uppercase mb-0 mt-0">
                      Classes
                    </h4>
                  </div>
                  <div className="col-5 text-left">
                    <ApexCharts
                      options={classesOptions}
                      series={classesSeries}
                      type="bar"
                      height={"50%"}
                      width={"100%"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-12">
            <div className="card w-100 border-0 rounded-lg bg-white shadow-xs overflow-hidden">
              <div className="card-body p-4 rounded-lg">
                <div className="row">
                  <div className="col-7">
                    <h2 className="text-grey-900 fw-700 display1-size mt-2 mb-2 ls-3 lh-1">
                      {analytics?.exams || "-"}
                    </h2>
                    <h4 className="fw-700 text-grey-500 font-xsssss ls-3 text-uppercase mb-0 mt-0">
                      Exams
                    </h4>
                  </div>
                  <div className="col-5 text-left">
                    <ApexCharts
                      options={examsOptions}
                      series={examsSeries}
                      type="bar"
                      height={"50%"}
                      width={"100%"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfx" />
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import env from "../../../../env";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";

function ProgramEvaluation() {
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const navigate = useNavigate();
  let isError = false;

  const [programs, setPrograms] = useState([]);
  const [program, setProgram] = useState("");
  const [programNames, setProgramNames] = useState([]);
  const [name, setName] = useState("");
  const [evaluation, setEvaluation] = useState("");

  useEffect(() => {
    if (!programs.length) {
      getPrograms();
    }
  });

  const getPrograms = () => {
    axios
      .get(baseUrl + "/programs", config)
      .then((response) => {
        setPrograms(response.data.programs);
        const progs = [];
        response.data.programs.forEach((program) => {
          progs.push({ value: program.id, label: program.name });
        });
        setProgramNames(progs);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!name.length) {
      fieldName.push("Program");
    }
    if (!evaluation.length) {
      fieldName.push("Evaluation");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (isError) {
      return;
    }
    updateProgram();
  };

  const clearFields = () => {
    setProgram("");
    setName("");
    setEvaluation("");
  };

  const editProgram = (prog) => {
    setProgram(prog.id);
    setName(prog.name);
    setEvaluation(prog.evaluation || "");
  };

  const updateProgram = () => {
    const prog = programs.find((pro) => pro.id === program);
    axios
      .post(
        baseUrl + "/program/" + prog.id,
        {
          coordinator_id: prog.coordinator_id,
          name: name,
          description: prog.description,
          duration: prog.duration,
          alignment: prog.alignment,
          curriculum: prog.curriculum,
          goals: prog.goals,
          evaluation: evaluation,
          courses: prog.courses.map((course) => course.id),
          status: prog.status,
        },
        config
      )
      .then((response) => {
        toast.success("Program Evaluation Updated Successfully!");
        getPrograms();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });

    clearFields();
  };

  const handleProgramChange = (selectedValue) => {
    const prog = programs.find((program) => program.id === selectedValue.value);
    editProgram(prog);
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Program Evaluation
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Program</label>
                <Select
                  isSearchable={false}
                  options={programNames}
                  value={
                    programNames.find((option) => option.value === program) ||
                    ""
                  }
                  onChange={handleProgramChange}
                  placeholder="Select Program"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Evaluation</label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  onChange={(e) => setEvaluation(e.target.value)}
                  value={evaluation}
                />
              </div>
            </div>
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w250 rounded-lg d-inline-block"
                onClick={(e) => handleSubmit(e)}
              >
                Update Program Evaluation
              </Link>
            </div>
          </div>
        </form>
      </div>
      {programs.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Program ID</th>
                <th className="bg-current text-white">Program Name</th>
                <th className="bg-current text-white">Evaluation</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {programs.map((program) => (
                <tr key={program.id}>
                  <td>{program.id}</td>
                  <td>{program.name}</td>
                  <td>{program.evaluation}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current"
                      style={{ cursor: "pointer" }}
                      onClick={() => editProgram(program)}
                      onKeyDown={(e) => {}}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ProgramEvaluation;

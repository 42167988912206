import { Link } from "react-router-dom";

function ManageInstructor() {
  return (
    <div className="row">
      <div className="col-xl-7 col-lg-6 col-md-6">
        <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-xxl-5 p-4 border-0 text-center">
          <Link
            to="/dashboard/instructor/create-courses"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-3"
          >
            Course Management
          </Link>
          <Link
            to="/dashboard/instructor/course-content"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-3"
          >
            Course Content
          </Link>
          <Link
            to="/dashboard/instructor/course-materials"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-3"
          >
            Course Materials
          </Link>
          <Link
            to="/dashboard/instructor/manage-assignments"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-3"
          >
            Manage Assignments
          </Link>
          <Link
            to="/dashboard/instructor/manage-submissions"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-3"
          >
            Manage Submissions
          </Link>
          <Link
            to="/dashboard/instructor/create-exam"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-3"
          >
            Exam Creation
          </Link>
          <Link
            to="/dashboard/instructor/grade-students"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-3"
          >
            Grade Students
          </Link>
          <Link
            to="/dashboard/instructor/reports"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-3"
          >
            Reports
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ManageInstructor;

import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import env from "../../../../env";
import axios from "axios";
import { toast } from "react-toastify";

const grades = [
  { value: "A+", label: "A+" },
  { value: "A", label: "A" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B", label: "B" },
  { value: "B-", label: "B-" },
  { value: "C+", label: "C+" },
  { value: "C", label: "C" },
  { value: "C-", label: "C-" },
  { value: "D+", label: "D+" },
  { value: "D", label: "D" },
  { value: "D-", label: "D-" },
  { value: "F", label: "F" },
];

function GradeStudents() {
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [exams, setExams] = useState([]);
  const [exam, setExam] = useState(null);
  const [examScores, setExamScores] = useState([]);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (!exams.length) {
      getExams();
    }
  });

  const getExams = () => {
    axios
      .get(baseUrl + "/exams", config)
      .then((response) => {
        const exm = [];
        response.data.exams.forEach((exam) => {
          exm.push({ value: exam.id, label: exam.name });
        });
        setExams(exm);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleExamChange = (selectedValue) => {
    setExam(selectedValue);
  };

  const handleFilterStudents = () => {
    config.params = {
      exam_id: exam,
    };

    axios
      .get(baseUrl + "/exam-scores", config)
      .then((response) => {
        setExamScores(response.data.grades);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleGradeSelection = (grade, id) => {
    const formData = new FormData();
    formData.append("grade", grade);

    axios
      .post(baseUrl + "/exam-scores/" + id, formData, config)
      .then((response) => {
        toast.success(response.data.message);
        handleFilterStudents();
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Grade Students
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Exam</label>
                <Select
                  isSearchable={false}
                  options={exams}
                  value={exams.find((option) => option.value === exam)}
                  onChange={(e) => (e ? handleExamChange(e.value) : "")}
                  placeholder="Select a Exam"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={handleFilterStudents}
              >
                Filter Students
              </Link>
            </div>
          </div>
        </form>
      </div>
      {examScores?.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Student Name</th>
                <th className="bg-current text-white">Course Name</th>
                <th className="bg-current text-white">Exam Name</th>
                <th className="bg-current text-white">Score</th>
                <th className="bg-current text-white">Assign Grade</th>
              </tr>
            </thead>
            <tbody>
              {examScores.map((score) => (
                <tr key={score.id}>
                  <td>{score.student.name}</td>
                  <td>{score.course.name}</td>
                  <td>{score.exam.name}</td>
                  <td>
                    {score.score +
                      " / " +
                      JSON.parse(score.exam.questions).length * 10}
                  </td>
                  <td className="text-center">
                    <select
                      className="form-control"
                      name="grades"
                      id="grades"
                      onChange={(e) =>
                        handleGradeSelection(e.target.value, score.id)
                      }
                      value={score.grade}
                    >
                      <option value="">Grade</option>
                      {grades.map((grade) => (
                        <option key={grade.value} value={grade.value}>
                          {grade.label}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default GradeStudents;

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

function PerformanceAssessment() {
  const navigate = useNavigate();
  let isError = false;
  const [mode, setMode] = useState("add");
  const [id, setId] = useState(1);
  const [activeId, setActiveId] = useState(-1);
  const [assessments, setAssessments] = useState([]);
  const [instructorId] = useState([
    { value: "I01", label: "I01" },
    { value: "I02", label: "I02" },
    { value: "I03", label: "I03" },
    { value: "I04", label: "I04" },
  ]);

  const [studentId] = useState([
    { value: "S01", label: "S01" },
    { value: "S02", label: "S02" },
    { value: "S03", label: "S03" },
    { value: "S04", label: "S04" },
  ]);

  const [coordinatorId] = useState([
    { value: "C01", label: "C01" },
    { value: "C02", label: "C02" },
    { value: "C03", label: "C03" },
    { value: "C04", label: "C04" },
  ]);
  const [selectedInstructorId, setSelectedInstructorId] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState([]);
  const [selectedCoordinatorId, setSelectedCoordinatorId] = useState([]);
  const [assessment, setAssessment] = useState("");
  const handleInstructorIdSelection = (selectedValues) => {
    setSelectedInstructorId(selectedValues);
  };
  const handleStudentIdSelection = (selectedValues) => {
    setSelectedStudentId(selectedValues);
  };
  const handleCoordinatorIdSelection = (selectedValues) => {
    setSelectedCoordinatorId(selectedValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!instructorId.length) {
      fieldName.push("Instructor ID");
    }
    if (!studentId.length) {
      fieldName.push("Student ID");
    }
    if (!coordinatorId.length) {
      fieldName.push("Coordinator ID");
    }
    if (!assessment.length) {
      fieldName.push("Assessment ");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (isError) {
      return;
    }
    mode === "add" ? addAssessment() : updateAssessment();
  };
  const clearFields = () => {
    setSelectedStudentId("");
    setSelectedCoordinatorId("");
    setAssessment("");
    setSelectedInstructorId("");
  };
  const addAssessment = () => {
    const newAssessment = {
      id: id,
      selectedInstructorId: selectedInstructorId,
      selectedStudentId: selectedStudentId,
      selectedCoordinatorId: selectedCoordinatorId,
      assessment: assessment,
    };

    setAssessments((prevAssessments) => [...prevAssessments, newAssessment]);

    clearFields();
    setId(id + 1);
  };
  const editAssessment = (id) => {
    setMode("edit");
    setActiveId(id);
    const assessment = assessments.find((assessment) => assessment.id === id);
    setSelectedCoordinatorId(assessment.selectedCoordinatorId);
    setSelectedStudentId(assessment.selectedStudentId);
    setSelectedInstructorId(assessment.selectedInstructorId);
    setAssessment(assessment.assessment);
  };
  const deleteAssessment = (id) => {
    const newAssessments = assessments.filter(
      (assessment) => assessment.id !== id
    );
    setAssessments(newAssessments);
  };
  const updateAssessment = () => {
    const nassessment = assessments.find(
      (assessment) => assessment.id === activeId
    );
    nassessment.selectedCoordinatorId = selectedCoordinatorId;
    nassessment.selectedStudentId = selectedStudentId;
    nassessment.selectedInstructorId = selectedInstructorId;
    nassessment.assessment = assessment;

    clearFields();
    setMode("add");
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Performance Assessment
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Instructor ID
                </label>
                <Select
                  isMulti
                  options={instructorId}
                  value={selectedInstructorId}
                  onChange={handleInstructorIdSelection}
                  placeholder="Select Courses"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Student ID</label>
                <Select
                  isMulti
                  options={studentId}
                  value={selectedStudentId}
                  onChange={handleStudentIdSelection}
                  placeholder="Select Courses"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Coordinator ID
                </label>
                <Select
                  isMulti
                  options={coordinatorId}
                  value={selectedCoordinatorId}
                  onChange={handleCoordinatorIdSelection}
                  placeholder="Select Courses"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Assessment</label>
                <input
                  type="text"
                  name="comment-name"
                  className="form-control"
                  onChange={(e) => setAssessment(e.target.value)}
                  value={assessment}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={(e) => handleSubmit(e)}
              >
                {mode === "add" ? "Add" : "Edit"} Assessment
              </Link>
            </div>
          </div>
        </form>
      </div>
      {assessments.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Assessment ID</th>
                <th className="bg-current text-white">Instructor ID</th>
                <th className="bg-current text-white">Student ID</th>
                <th className="bg-current text-white">Coordinator ID</th>
                <th className="bg-current text-white">Assessment</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {assessments.map((assessment) => (
                <tr key={assessment.id}>
                  <td>{assessment.id}</td>
                  <td>
                    {assessment.selectedInstructorId
                      .map((id) => id.label)
                      .join(", ")}
                  </td>
                  <td>
                    {assessment.selectedStudentId
                      .map((id) => id.label)
                      .join(", ")}
                  </td>
                  <td>
                    {assessment.selectedCoordinatorId
                      .map((id) => id.label)
                      .join(", ")}
                  </td>
                  <td>{assessment.assessment}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current mr-sm-2 mr-md-3 mr-lg-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => editAssessment(assessment.id)}
                    />
                    <i
                      className="feather-trash text-red"
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteAssessment(assessment.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default PerformanceAssessment;

import { Link } from "react-router-dom";

function ManageQA() {
  return (
    <div className="row">
      <div className="col-xl-8 col-lg-6 col-md-6">
        <div className="card d-block w-100 shadow-xss rounded-lg p-xxl-4 p-4 border-0 text-center">
          <Link
            to="/dashboard/qa/curriculum-evaluation"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-2"
          >
            Curriculum Evaluation
          </Link>
          {/* <Link
            to="/dashboard/qa/performance-assessment"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-2"
          >
            Performance Assessment
          </Link> */}
          <Link
            to="/dashboard/qa/qs-compliance"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-2"
          >
            QS Compliance
          </Link>
          <Link
            to="/dashboard/qa/support-and-enquiries"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-2"
          >
            Support & Enquiries
          </Link>
          <Link
            to="/dashboard/qa/faculty-development"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-2"
          >
            Faculty Development
          </Link>
          {/* <Link
            to="/dashboard/qa/assessment-tools"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-2"
          >
            Assessment Tools
          </Link> */}
          <Link
            to="/dashboard/qa/improvement-initiatives"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-2"
          >
            Improvement Initiatives
          </Link>
          <Link
            to="/dashboard/qa/reporting"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-2"
          >
            Reports
          </Link>
          {/* <Link
            to="/dashboard/qa/accreditation"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-2"
          >
            Accreditation
          </Link>
          <Link
            to="/dashboard/qa/reviews"
            className="p-2 mt-4 mb-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current mr-2"
          >
            Reviews
          </Link> */}
        </div>
      </div>
    </div>
  );
}

export default ManageQA;

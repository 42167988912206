import { useNavigate, Link } from "react-router-dom";
import env from "../../../../env";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";

function CourseDetails(props) {
  const navigate = useNavigate();
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const course_id = localStorage.getItem("course_id");

  const [courseDetails, setCourseDetails] = useState(null);
  const [studentCourses, setStudentCourses] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [courseMaterials, setCourseMaterials] = useState([]);
  const [courseAssignments, setCourseAssignments] = useState([]);

  const [submissionFile, setSubmissionFile] = useState(null);
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    if (!course_id) {
      navigate(`/dashboard/${user.role}`);
    }
    if (!courseDetails) {
      getCourseDetails();
      getStudentCourses();
      getCourseMaterials();
      getCourseAssignments();
      getSubmissions();
    }
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getCourseDetails = () => {
    axios
      .get(baseUrl + "/course/" + course_id, config)
      .then((response) => {
        setCourseDetails(response.data.course);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getStudentCourses = () => {
    axios
      .get(baseUrl + "/admin/my-courses", config)
      .then((response) => {
        setStudentCourses(response.data.myCourses);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleCourseRegistration = (e) => {
    e.preventDefault();
    const crs = studentCourses.courses
      ? studentCourses.courses.map((cor) => cor.id)
      : [];
    const newStudent = {
      student_id: user.id,
      courses: [...crs, parseInt(course_id)],
    };

    if (crs.length > 0) {
      axios
        .post(
          baseUrl + "/admin/student-courses/" + studentCourses.id,
          newStudent,
          config
        )
        .then((response) => {
          toast.success(response.data.message);
          getStudentCourses();
        })
        .catch(function (error) {
          const tempdata = error.response.data;
          toast.error(`${tempdata.message}`);
        });
    } else {
      axios
        .post(baseUrl + "/admin/student-courses", newStudent, config)
        .then((response) => {
          toast.success(response.data.message);
          getStudentCourses();
        })
        .catch(function (error) {
          const tempdata = error.response.data;
          toast.error(`${tempdata.message}`);
        });
    }
  };

  const getCourseMaterials = () => {
    axios
      .get(baseUrl + "/materials/" + course_id, config)
      .then((response) => {
        setCourseMaterials(response.data.materials);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getCourseAssignments = () => {
    axios
      .get(baseUrl + "/assignments/" + course_id, config)
      .then((response) => {
        setCourseAssignments(response.data.assignments);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleAssignmentSubmission = (assignmentId) => {
    if (!submissionFile) {
      toast.error("Please select a file!");
      return;
    }
    const formData = new FormData();
    formData.append("assignment_id", assignmentId);
    formData.append("student_id", user.id);
    formData.append("file", submissionFile);

    axios
      .post(baseUrl + "/submission", formData, config)
      .then((response) => {
        toast.success(response.data.message);
        getCourseAssignments();
        setSubmissionFile(null);
        getSubmissions();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getSubmissions = () => {
    axios
      .get(baseUrl + "/submissions", config)
      .then((response) => {
        setSubmissions(response.data.submissions);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  return (
    <div>
      <div className="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4 bg-lightblue">
        <ul
          className="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-between product-info-tab border-bottom-0"
          id="pills-tab"
          role="tablist"
        >
          <li className="active list-inline-item">
            <Link
              className="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 ml-sm-5 font-xsss text-grey-500 ls-3 d-inline-block text-uppercase active"
              to="#details"
              data-toggle="tab"
              onClick={() => setActiveTab(1)}
            >
              Course Details
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              className="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 font-xsss text-grey-500 ls-3 d-inline-block text-uppercase"
              to="#materials"
              data-toggle="tab"
              onClick={() => setActiveTab(2)}
            >
              Course Materials
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              className="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 mr-sm-5 font-xsss text-grey-500 ls-3 d-inline-block text-uppercase"
              to="#assignments"
              data-toggle="tab"
              onClick={() => setActiveTab(3)}
            >
              Assignments
            </Link>
          </li>
        </ul>
      </div>
      {activeTab === 1 && (
        <div className="row">
          {courseDetails && (
            <div className="col-xl-8 col-xxl-9">
              <div
                className="card border-0 mb-0 rounded-lg overflow-hidden"
                style={{ backgroundImage: "url(images/ivan-samkov.jpg)" }}
              >
                <div className="card-body p-5 bg-black-08">
                  <h2 className="fw-700 font-lg d-block lh-4 mb-1 text-white mt-2">
                    {courseDetails.name}
                  </h2>
                  <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
                    Instructor Name - {courseDetails.instructor.name}
                  </span>
                  <div className="clearfix" />
                  <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
                    Email - {courseDetails.instructor.email}
                  </span>
                  <div className="clearfix" />
                  <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
                    Phone - {courseDetails.instructor.phone}
                  </span>
                  <div className="clearfix" />
                  <div className="star d-block w-100 text-left mt-2">
                    <img
                      src="/images/star.png"
                      alt="star"
                      className="w15 float-left mr-1"
                    />
                    <p className="review-link font-xssss fw-600 text-grey-500 lh-3">
                      {parseFloat(courseDetails.rating).toFixed(1)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="card d-block border-0 rounded-lg overflow-hidden mt-4">
                <div id="accordion" className="accordion mb-0">
                  {courseDetails.sections.map((section, sectionIdx) => (
                    <div className="card shadow-xss mb-0" key={section.id}>
                      <div
                        className="card-header bg-greylight theme-dark-bg"
                        id={"section-" + sectionIdx}
                      >
                        <h5 className="mb-0">
                          <button
                            className="btn font-xsss fw-600 btn-link collapsed"
                            data-toggle="collapse"
                            data-target={"#collapse" + sectionIdx}
                            aria-expanded="false"
                            aria-controls={"collapse" + sectionIdx}
                          >
                            {section.name}
                          </button>
                        </h5>
                      </div>
                      <div
                        id={"collapse" + sectionIdx}
                        className="collapse p-3"
                        aria-labelledby={"section-" + sectionIdx}
                        data-parent="#accordion"
                      >
                        {courseDetails.lessons[sectionIdx].map(
                          (lesson, lessonIdx) => (
                            <div
                              className="card-body d-flex p-2"
                              key={lesson.id}
                            >
                              <span className="bg-current btn-round-xs rounded-lg font-xssss text-white fw-600">
                                {lessonIdx + 1}
                              </span>
                              <span className="font-xssss fw-500 text-grey-500 ml-2">
                                {lesson.name}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4 alert-success">
                <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 text-success mb-4">
                  What you'll learn from this lesson
                </h2>
                <h4 className="font-xssss fw-600 text-grey-600 mb-3 pl-1 position-relative lh-24">
                  {courseDetails.learnings}
                </h4>
              </div>
              <div className="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4">
                <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">
                  Description
                </h2>
                <p className="font-xssss fw-500 lh-28 text-grey-600 mb-0 pl-2">
                  {courseDetails.description}
                </p>
              </div>
              <div className="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4 mb-5">
                <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">
                  Requirements
                </h2>
                <p className="font-xssss fw-500 lh-28 text-grey-600 mb-0 pl-2">
                  {courseDetails.requirements}
                </p>
              </div>
            </div>
          )}
          {courseDetails && (
            <div className="col-xl-4 col-xxl-3">
              <div className="card overflow-hidden subscribe-widget p-3 mb-3 rounded-xxl border-0 shadow-xss">
                <div className="card-body p-3 d-block text-left">
                  <h4 className="pl-35 mb-4 font-xsss fw-600 text-grey-900 position-relative">
                    <i className="feather-database font-lg text-current position-absolute left-0" />{" "}
                    Language
                    <span className="d-block text-grey-500 mt-1 font-xssss">
                      {JSON.parse(courseDetails.languages).join(", ")}
                    </span>
                  </h4>
                  <h4 className="pl-35 mb-0 font-xsss fw-600 text-grey-900 position-relative">
                    <i className="feather-award font-lg text-current position-absolute left-0" />{" "}
                    Duration
                    <span className="d-block text-grey-500 mt-1 font-xssss">
                      {courseDetails.duration}
                    </span>
                  </h4>
                  {courseDetails.id &&
                  !studentCourses?.courses?.find(
                    (course) => course.id === courseDetails.id
                  ) ? (
                    <Link
                      className="bg-primary-gradiant border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-lg d-block mt-4 w-100 p-2 lh-34 text-center ls-3"
                      onClick={handleCourseRegistration}
                    >
                      Register Now
                    </Link>
                  ) : (
                    <Link className="alert-success border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-lg d-block mt-4 w-100 p-2 lh-34 text-center ls-3">
                      <span className="text-success">Registered</span>
                    </Link>
                  )}
                </div>
              </div>
              <div className="card shadow-xss rounded-lg border-0 p-4 mb-4">
                <h4 className="font-xs fw-700 text-grey-900 d-block mb-3">
                  Modules
                </h4>
                {courseDetails.sections.map((section, sectionIdx) => (
                  <div className="card-body d-flex p-0" key={section.id}>
                    <span className="bg-current btn-round-xs rounded-lg font-xssss text-white fw-600">
                      {sectionIdx + 1}
                    </span>
                    <span className="font-xssss fw-500 text-grey-500 ml-2">
                      {section.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      {activeTab === 2 && (
        <div className="row">
          {courseMaterials?.length > 0 ? (
            courseMaterials.map((material) => (
              <div className="col-lg-3 mb-3" key={material.id}>
                <div
                  className="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-1"
                  style={{ width: "fit-content" }}
                >
                  <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">
                    {material.name}
                  </h2>
                  <p className="font-xssss fw-500 lh-28 text-grey-600 mb-0">
                    <Link
                      to={material.file}
                      target="_blank"
                      className="btn btn-primary text-white fw-600 text-uppercase font-xsssss"
                    >
                      Download
                    </Link>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="col-lg-12 mt-5 mb-5 text-center">
              <h4>There are No Course Materials added yet</h4>
            </div>
          )}
        </div>
      )}
      {activeTab === 3 && (
        <div>
          <div className="row">
            {courseAssignments?.length > 0 &&
            courseAssignments.some((cass) => cass.status === "active") ? (
              courseAssignments.map(
                (assignment) =>
                  assignment.status === "active" && (
                    <div className="col-lg-12 mb-3" key={assignment.id}>
                      <div
                        className="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-1"
                        style={{ width: "fit-content" }}
                      >
                        <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">
                          {assignment.title}
                        </h2>
                        <p className="font-xssss fw-500 lh-28 text-grey-600 ml-1">
                          {assignment.description}
                        </p>
                        <p className="font-xssss fw-500 lh-28 ml-1 text-danger">
                          Due Date : {new Date(assignment.due).toLocaleString()}
                        </p>
                        <p className="font-xsss fw-500 lh-28 ml-1 text-grey-600">
                          <label>
                            Upload your submission file here :<br />
                            <span className="text-info font-xssss">
                              Max File Size {"<="} 2 MB
                            </span>
                            <br />
                            <span className="text-info font-xssss">
                              Supported File Types : pdf, doc, docx
                            </span>
                          </label>
                          <br />
                          {submissions?.length > 0 &&
                          submissions.some(
                            (submission) =>
                              submission.student_id === user.id &&
                              submission.assignment_id === assignment.id
                          ) ? null : (
                            <input
                              className="font-xssss fw-500 lh-28 text-grey-600"
                              type="file"
                              name="material-file"
                              accept=".pdf, .doc, .docx"
                              onChange={(e) =>
                                setSubmissionFile(e.target.files[0])
                              }
                            />
                          )}
                        </p>
                        <p className="font-xsss fw-500 lh-28 text-grey-600 mb-0 ml-1">
                          <Link
                            to={assignment.file}
                            target="_blank"
                            className="btn btn-primary text-white fw-600 text-uppercase font-xssss mr-1"
                          >
                            Download
                          </Link>
                          {(submissions?.length > 0 &&
                            submissions.some(
                              (submission) =>
                                submission.student_id === user.id &&
                                submission.assignment_id === assignment.id
                            )) ||
                          !studentCourses?.courses?.find(
                            (course) => course.id === courseDetails.id
                          ) ? null : (
                            <Link
                              className="btn btn-success text-white fw-600 text-uppercase font-xssss float-right mr-1"
                              onClick={() =>
                                handleAssignmentSubmission(assignment.id)
                              }
                            >
                              Submit Assignment
                            </Link>
                          )}
                        </p>
                      </div>
                    </div>
                  )
              )
            ) : (
              <div className="col-lg-12 mt-5 mb-5 text-center">
                <h4>There are No Course Assignments added yet</h4>
              </div>
            )}
          </div>
          {submissions?.length > 0 &&
            submissions.some(
              (sub) =>
                sub.student_id === user.id &&
                sub.assignment.course_id.toString() === course_id.toString()
            ) && (
              <div className="card-body w-100 table-responsive">
                <table className="table table-bordered data-table">
                  <thead>
                    <tr>
                      <th className="bg-current text-white">Submission ID</th>
                      <th className="bg-current text-white">Assignment</th>
                      <th className="bg-current text-white">File</th>
                      <th className="bg-current text-white">Feedback</th>
                      <th className="bg-current text-white">Grade</th>
                      <th className="bg-current text-white">Submitted At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {submissions.map(
                      (submission) =>
                        submission.student_id === user.id &&
                        submission.assignment.course_id.toString() ===
                          course_id.toString() && (
                          <tr key={submission.id}>
                            <td>{submission.id}</td>
                            <td>{submission.assignment.title}</td>
                            <td>
                              <Link
                                className="text-primary"
                                to={submission.file}
                                target="_blank"
                              >
                                Download
                              </Link>
                            </td>
                            <td>
                              {submission.feedback || "Yet to be reviewed"}
                            </td>
                            <td>{submission.grade || "Yet to be reviewed"}</td>
                            <td>
                              {new Date(submission.created_at).toLocaleString()}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default CourseDetails;

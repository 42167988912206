import { Link } from "react-router-dom";
import env from "../../env";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useTimer } from "react-timer-hook";

function Exam() {
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const student_id = user.id;

  const [exams, setExams] = useState([]);
  const [exam, setExam] = useState(null);
  const [question, setQuestion] = useState(null);
  const [questionCount, setQuestionCount] = useState(1);
  const [optionSelected, setOptionSelected] = useState(null);
  const [score, setScore] = useState(0);
  const [grades, setGrades] = useState([]);
  const [studentCourses, setStudentCourses] = useState([]);

  const { seconds, minutes, pause, restart } = useTimer({
    onExpire: () => {
      toast.error("Time Up!");
      setExam(null);
      saveExamScore();
    },
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (!exams.length) {
      getMyCourses();
      getExams();
      getGrades();
    }
  });

  const getExams = () => {
    axios
      .get(baseUrl + "/exams", config)
      .then((response) => {
        setExams(response.data.exams);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getGrades = () => {
    axios
      .get(baseUrl + "/exam-scores", config)
      .then((response) => {
        setGrades(response.data.grades);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const startExam = (item) => {
    setExam(item);
    setQuestion(item.questions[0]);
    restart(Date.now() + item.duration * 60 * 1000);
  };

  const nextQuestion = () => {
    setOptionSelected(null);
    if (questionCount < exam.questions.length) {
      setQuestion(exam.questions[questionCount]);
      setQuestionCount(questionCount + 1);
    } else {
      pause();
      setQuestion(null);
      saveExamScore();
    }
  };

  const handleOptionClick = (option, idx) => {
    setOptionSelected(idx);
    if (option === exam.questions[questionCount - 1].correctAnswer) {
      setScore(parseInt(score) + 10);
    }
  };

  const saveExamScore = () => {
    const examScore = {
      student_id: student_id,
      course_id: exam.course_id,
      exam_id: exam.id,
      score: score,
    };

    axios
      .post(baseUrl + "/exam-score", examScore, config)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getMyCourses = () => {
    axios
      .get(baseUrl + "/admin/my-courses", config)
      .then((response) => {
        setStudentCourses(response.data.myCourses);
      })
      .catch((error) => {
        toast.error(`${error}`);
      });
  };

  return (
    <div className="middle-sidebar-left" style={{ height: "fit-content" }}>
      <div className="row">
        {exams?.length > 0 &&
        !exam &&
        studentCourses?.courses?.length > 0 &&
        exams?.filter((exa) =>
          studentCourses.courses?.some(
            (cor) => cor.id === exa.course_id && cor.status === "active"
          )
        ).length > 0 ? (
          exams.map(
            (item) =>
              item.status === "active" &&
              studentCourses.courses?.some(
                (scor) => scor.id.toString() === item.course_id.toString()
              ) && (
                <div className="col-xxl-4 col-xl-4 col-md-12" key={item.id}>
                  <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-md-5 p-4 border-0 text-center">
                    <img
                      src="/images/fogg-clip.png"
                      alt="icon"
                      className="p-1 img-fluid"
                    />
                    <h4 className="fw-700 font-xs mt-4">{item.name}</h4>
                    <div className="clearfix" />
                    <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-info d-inline-block text-info">
                      Test Duration : {item.duration} Min
                    </span>
                    <div className="clearfix" />
                    <div className="card-body p-0 w250 ml-auto mr-auto">
                      <div className="timer mt-4 mb-2" />
                    </div>
                    {grades.filter(
                      (grade) =>
                        grade.exam_id === item.id &&
                        grade.student_id === student_id
                    ).length > 0 ? (
                      <div>
                        <Link
                          class="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xssss ls-3 bg-success"
                          style={{ cursor: "default" }}
                        >
                          Score :{" "}
                          {
                            grades.filter(
                              (grade) =>
                                grade.exam_id === item.id &&
                                grade.student_id === student_id
                            )[0].score
                          }
                          /{item.questions.length * 10}{" "}
                          {grades.filter(
                            (grade) =>
                              grade.exam_id === item.id &&
                              grade.student_id === student_id
                          )[0].grade && (
                            <span>
                              | Grade :{" "}
                              {
                                grades.filter(
                                  (grade) =>
                                    grade.exam_id === item.id &&
                                    grade.student_id === student_id
                                )[0].grade
                              }
                            </span>
                          )}
                        </Link>
                      </div>
                    ) : (
                      <Link
                        class="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
                        onClick={() => startExam(item)}
                      >
                        Take Test
                      </Link>
                    )}
                  </div>
                </div>
              )
          )
        ) : (
          <div className="col-xxl-12 col-xl-12 col-md-12 mt-5 text-center">
            <img
              src="/images/fogg-clip.png"
              alt="icon"
              className="img-fluid mt-5"
              style={{ height: "10rem", width: "15rem" }}
            />
            <h4 className="mt-3 p-5 ml-5 font-lg text-grey-500">
              No Exams Available
            </h4>
          </div>
        )}
        {exam && (
          <div>
            <div className="col-xxl-8 col-xl-12 col-md-12">
              <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-3 border-0 text-left question-div">
                <div className="card-body p-0">
                  <h4 className="font-xssss text-uppercase text-current fw-700 ls-3 mt-2">
                    Please do not refresh the page or close the browser{" "}
                    <span
                      id="timer"
                      className="bg-lightblue theme-dark-bg rounded-lg font-xsss fw-600 text-red p-2 mt-n2 float-right ml-5"
                    >
                      Time Left : {minutes}:{seconds}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xxl-8 col-xl-12 col-md-12">
              <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-5 border-0 text-left question-div">
                {question && (
                  <div className="card-body p-0" id="question">
                    <h4 className="font-xssss text-uppercase text-current fw-700 ls-3">
                      Question {questionCount}
                    </h4>
                    <h3 className="font-sm text-grey-800 fw-700 lh-32 mt-4 mb-4">
                      {question.question}
                    </h3>
                    {question.options.map((option, idx) => (
                      <p
                        key={option}
                        onClick={() => handleOptionClick(option, idx)}
                        className={
                          "bg-lightblue theme-dark-bg  p-4 mt-3 question-ans style1 rounded-lg font-xsss fw-600 lh-30 text-grey-700 mb-0 p-2 " +
                          (optionSelected === idx ? "active" : "")
                        }
                        onKeyDown={(e) => {}}
                      >
                        {option}
                      </p>
                    ))}
                    <Link
                      onClick={nextQuestion}
                      className="next-bttn p-2 mt-3 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
                    >
                      NEXT
                    </Link>
                  </div>
                )}
                {!question && (
                  <div
                    className="card-body text-center p-3 bg-no-repeat bg-image-topcenter"
                    id="question4"
                    style={{
                      backgroundImage: "url(images/user-pattern.png)",
                    }}
                  >
                    <img
                      src="/images/world-cup.png"
                      alt="icon"
                      className="d-inline-block"
                    />
                    <h2 className="fw-700 mt-5 text-grey-900 font-xxl">
                      Congratulations!
                    </h2>
                    <p className="font-xssss fw-600 lh-30 text-grey-500 mb-0 p-2">
                      You have completed the test. <br /> If you have any
                      queries, please talk to your coodinator.
                    </p>
                    <Link
                      onClick={() => window.location.reload()}
                      className="p-2 pl-3 pr-3 mt-3 d-inline-block text-white fw-700 lh-30 rounded-lg text-center font-xssss ls-3 bg-current"
                    >
                      YOUR SCORE : {score} / {exam.questions.length * 10}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Exam;

import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import env from "../../../../env";
import axios from "axios";
import { toast } from "react-toastify";

function SupportAndEnquiries() {
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    query: "",
    resolution: "",
  });

  const [dataLoaded, setDataLoaded] = useState(false);
  const [supports, setSupports] = useState([]);
  const [supportId, setSupportId] = useState(null);

  useEffect(() => {
    if (!dataLoaded) {
      getSupports();
    }
  });

  const getSupports = () => {
    axios
      .get(baseUrl + "/supports", config)
      .then((response) => {
        setSupports(response.data.supports);
        setDataLoaded(true);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleEdit = (id) => {
    setSupportId(id);
    const support = supports.find((support) => support.id === id);
    setFormData({
      name: support.name,
      email: support.email,
      query: support.query,
      resolution: support.resolution || "",
    });
  };

  const handleResolve = (e) => {
    e.preventDefault();
    if (!supportId) {
      toast.error("Please select a Support Query!");
      return;
    }
    axios
      .post(baseUrl + "/support/" + supportId, formData, config)
      .then((response) => {
        toast.success(response.data.message);
        setFormData({
          name: "",
          email: "",
          query: "",
          resolution: "",
        });
        getSupports();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Support & Enquiries
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Name</label>
                <input
                  type="text"
                  name="courseName"
                  className="form-control"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Email</label>
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Query</label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  name="query"
                  value={formData.query}
                  onChange={(e) =>
                    setFormData({ ...formData, query: e.target.value })
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Resolution</label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  name="resolution"
                  value={formData.resolution}
                  onChange={(e) =>
                    setFormData({ ...formData, resolution: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                onClick={handleResolve}
              >
                Resolve Query
              </Link>
            </div>
          </div>
        </form>
      </div>
      {supports?.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Name</th>
                <th className="bg-current text-white">Email</th>
                <th className="bg-current text-white">Query</th>
                <th className="bg-current text-white">Resolution</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {supports.map((support) => (
                <tr key={support.id}>
                  <td>{support.name}</td>
                  <td>{support.email}</td>
                  <td>{support.query}</td>
                  <td>{support.resolution}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEdit(support.id)}
                      onKeyDown={(e) => {}}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SupportAndEnquiries;

import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import env from "../../../../env";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";

function ManageStudent() {
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");

  const cgpa = localStorage.getItem("CGPA");
  const [courses, setCourses] = useState([]);
  const [studentCourses, setStudentCourses] = useState([]);
  const [programs, setPrograms] = useState({});

  const colors = ["#fcf1eb", "#fff9eb", "#e5f4fb", "#dcf4f8"];

  useEffect(() => {
    if (!courses.length) {
      getCourses();
      getStudentCourses();
      getPrograms();
    }

    if (!cgpa) {
      localStorage.setItem(
        "CGPA",
        (Math.random().toFixed(2) * 3 + 2).toFixed(2)
      );
    }
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getCourses = () => {
    axios
      .get(baseUrl + "/courses", config)
      .then((response) => {
        setCourses(response.data.courses);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getStudentCourses = () => {
    axios
      .get(baseUrl + "/admin/my-courses", config)
      .then((response) => {
        const studCourses = response.data.myCourses;
        if (studCourses && studCourses.courses?.length > 0) {
          for (let i = 0; i < studCourses.courses.length; i++) {
            studCourses.courses[i].completion = Math.round(
              Math.random().toFixed(2) * 100
            );
          }
        }
        setStudentCourses(studCourses);
      })
      .catch((error) => {
        toast.error(`${error}`);
      });
  };

  const getPrograms = () => {
    axios
      .get(baseUrl + "/programs", config)
      .then((response) => {
        if (response?.data?.programs?.length)
          setPrograms(response.data.programs[0]);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 pt-2">
          <h2 className="fw-400 font-lg">
            <b>Program</b> Overview
          </h2>
        </div>
        <div className="col-lg-12 mt-3 mb-3">
          <div
            className="card p-3 border-0 rounded-lg"
            style={{ backgroundColor: "#fcf1eb" }}
          >
            <h3 className="mt-3">
              <b>{programs.name}</b>
            </h3>
            <p>{programs.description}</p>

            <h3>
              <b>Curriculum</b>
            </h3>
            <p>{programs.curriculum}</p>

            <h3>
              <b>Duration</b>
            </h3>
            <p>{programs.duration}</p>

            <h3>
              <b>CGPA</b>
            </h3>
            <p>{cgpa}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 pt-2">
          <h2 className="fw-400 font-lg">Explore</h2>
        </div>
        <div className="col-lg-12 mt-3 overflow-hidden">
          <OwlCarousel className="owl-carousel category-card owl-theme overflow-hidden overflow-visible-xl nav-none">
            {courses.map(
              (course) =>
                course.status === "active" && (
                  <div className="item" key={course.id}>
                    <div
                      className="card cat-card-hover mr-1 border-0 p-4 rounded-lg text-center"
                      style={{
                        backgroundColor:
                          colors[Math.floor(Math.random() * colors.length)],
                      }}
                    >
                      <Link
                        to="/dashboard/student/course-details"
                        onClick={() => {
                          localStorage.setItem("course_id", course.id);
                        }}
                      >
                        <div className="card-body p-2 ml-1">
                          <h4 className="fw-600 font-xsss mt-3 mb-0">
                            {course.name}
                            <span className="d-block font-xsssss fw-500 text-grey-500 mt-2">
                              {course.duration}
                            </span>
                          </h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
            )}
          </OwlCarousel>
        </div>

        <div className="col-lg-12 pt-4 mb-3">
          <h2 className="fw-400 font-lg d-block">
            My <b> Courses</b>
          </h2>
        </div>
        <div className="col-lg-12 pt-2 overflow-hidden pb-5">
          {studentCourses && Object.keys(studentCourses).length ? (
            <OwlCarousel className="owl-carousel category-card owl-theme overflow-hidden overflow-visible-xl nav-none">
              {studentCourses.courses?.map(
                (course) =>
                  course.status === "active" && (
                    <div className="item" key={course.id}>
                      <div className="card course-card w300 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1">
                        <div className="card-body pt-0">
                          <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                            <Link
                              to="/dashboard/student/course-details"
                              className="text-dark text-grey-900"
                              onClick={() => {
                                localStorage.setItem("course_id", course.id);
                              }}
                            >
                              {course.name}
                            </Link>
                          </h4>
                          <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                            {JSON.parse(course.sections)?.length} Modules
                          </h6>
                          <div className="progress">
                            <div
                              className="progress-bar bg-info"
                              role="progressbar"
                              style={{
                                width: course.completion + "%",
                              }}
                              aria-valuenow={course.completion}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {course.completion}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </OwlCarousel>
          ) : (
            <h4 className="text-center text-grey-500">
              You haven't registered for any Courses yet!
            </h4>
          )}
        </div>
      </div>
    </div>
  );
}

export default ManageStudent;

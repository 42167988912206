import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import env from "../../../../env";
import axios from "axios";
import Select from "react-select";

function CourseContent() {
  const navigate = useNavigate();
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");

  const [allCourses, setAllCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState("");
  const [sectionTitle, setSectionTitle] = useState("");
  const [sections, setSections] = useState([]);
  const [lessonTitle, setLessonTitle] = useState([]);
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    if (!courses.length) {
      getCourses();
    }
  });

  const getCourses = () => {
    axios
      .get(baseUrl + "/courses", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let crs = [];
        for (const course of response.data.courses) {
          crs.push({ value: course.id, label: course.name });
        }
        setAllCourses(response.data.courses);
        setCourses(crs);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleCourseChange = (id) => {
    axios
      .get(baseUrl + "/course/" + id, config)
      .then((response) => {
        setCourse(id);
        setSections(response.data.course.sections);
        setLessons(response.data.course.lessons || []);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const saveCourseContent = (updatedSections) => {
    const activeCourse = allCourses.find((crs) => crs.id === course);
    const newCourse = {
      ...activeCourse,
      sections:
        updatedSections.length > 0
          ? updatedSections.map((section) => section.id)
          : [],
    };

    axios
      .post(baseUrl + "/course/" + course, newCourse, config)
      .then((response) => {
        toast.success(`${response.data.message}`);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const addSection = () => {
    if (!course) {
      toast.error("Please select a Course!");
      return;
    } else if (!sectionTitle.length) {
      toast.error("Section Title is Required!");
      return;
    } else {
      axios
        .post(
          baseUrl + "/section/" + course,
          {
            name: sectionTitle,
          },
          config
        )
        .then((response) => {
          const updatedSections = [
            ...sections,
            {
              id: response.data.section_id,
              name: sectionTitle,
            },
          ];
          setSections(updatedSections);
          setSectionTitle("");
          saveCourseContent(updatedSections);
        });
    }
  };

  const deleteSection = (id, sectionIndex) => {
    axios
      .delete(baseUrl + "/section/" + id, config)
      .then((response) => {
        const updatedLessons = [...lessons];
        updatedLessons[sectionIndex] = [];
        setLessons(updatedLessons);
        setSections((prevSections) =>
          prevSections.filter((section) => section.id !== id)
        );
        const updatedSections = sections.filter((section) => section.id !== id);
        saveCourseContent(updatedSections);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleLessonTitleChange = (value, sectionIndex) => {
    const newTitle = [...lessonTitle];
    newTitle[sectionIndex] = value;
    setLessonTitle(newTitle);
  };

  const addLesson = (section_id, sectionIndex) => {
    axios
      .post(
        baseUrl + "/lesson/" + section_id,
        {
          name: lessonTitle[sectionIndex],
        },
        config
      )
      .then((response) => {
        setLessonTitle("");
        const updatedLessons = [...lessons];
        for (let i = 0; i < sections.length; i++) {
          if (!updatedLessons[i]) {
            updatedLessons[i] = [];
          }
          if (i === sectionIndex) {
            updatedLessons[i].push({
              section_id: section_id,
              id: response.data.lesson_id,
              name: lessonTitle[i],
            });
          }
        }
        setLessons(updatedLessons);
        saveCourseContent(sections);

        const newTitle = [...lessonTitle];
        newTitle[sectionIndex] = "";
        setLessonTitle(newTitle);
      })
      .catch((error) => {
        console.log(error);
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const deleteLesson = (id) => {
    axios
      .delete(baseUrl + "/lesson/" + id, config)
      .then((response) => {
        handleCourseChange(course);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Course Content
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Course</label>
                <Select
                  isSearchable={false}
                  options={courses}
                  value={courses.find((option) => option.value === course)}
                  onChange={(e) => (e ? handleCourseChange(e.value) : "")}
                  placeholder="Select a Course"
                />
              </div>
            </div>
          </div>
        </form>
        <div className="section-form mb-4">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">Section Title</label>
            <input
              type="text"
              name="section-title"
              className="form-control"
              onChange={(e) => setSectionTitle(e.target.value)}
              value={sectionTitle}
            />
          </div>
          <button onClick={addSection} className="btn btn-primary">
            Add Section
          </button>
        </div>
        {sections.map((section, sectionIndex) => (
          <div key={sectionIndex} className="video-title-form mb-4">
            <div className="form-group">
              <label className="mont-font font-xss">
                Classes for Section:{" "}
                <span className="fw-600">{section.name}</span>
              </label>
              <Link
                className="float-right"
                onClick={() => deleteSection(section.id, sectionIndex)}
              >
                <i className="ti-trash text-red font-xs" />
              </Link>
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Class Title
                </label>
                <input
                  type="text"
                  name="section-title"
                  className="form-control"
                  onChange={(e) =>
                    handleLessonTitleChange(e.target.value, sectionIndex)
                  }
                  value={lessonTitle[sectionIndex]}
                />
              </div>
              <button
                onClick={() => addLesson(section.id, sectionIndex)}
                className="btn btn-primary"
              >
                Add Class
              </button>
            </div>
            <div className="card d-block border-0 rounded-lg overflow-hidden mt-4">
              <div className="mb-0">
                {lessons.length > 0 &&
                  lessons[sectionIndex]?.map((lesson, lessonIndex) => (
                    <div className="card shadow-xss mb-0" key={lessonIndex}>
                      <div
                        className="card-header bg-greylight theme-dark-bg"
                        id="headingOne"
                      >
                        <h5 className="mb-0">
                          <button
                            className="btn font-xsss fw-600"
                            style={{ cursor: "default" }}
                          >
                            {lesson.name}
                          </button>
                          <Link
                            className="float-right mt-2"
                            onClick={() => deleteLesson(lesson.id)}
                          >
                            <i className="ti-trash text-red font-xs" />
                          </Link>
                        </h5>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CourseContent;

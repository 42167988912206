import env from "../../../env";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

export default function Register() {
  const navigate = useNavigate();
  const baseUrl = env.BASE_URL;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCpassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const [captchaToken, setCaptchaToken] = useState(false);

  const onSubmitWithReCAPTCHA = (e) => {
    if (e) {
      setCaptchaToken(true);
    }
  };

  const roleOptions = [
    { value: "instructor", label: "Instructor" },
    { value: "coordinator", label: "Program Coordinator" },
    { value: "qa", label: "QA Officer" },
    { value: "student", label: "Student" },
  ];

  let isError = false;
  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!name.length) {
      fieldName.push("Name");
    }

    if (!email.length) {
      fieldName.push("Email");
    }
    if (!phoneNumber.length) {
      fieldName.push("Phone");
    }
    if (!password.length) {
      fieldName.push("Password");
    }
    if (!cPassword.length) {
      fieldName.push("Confirm Password");
    }
    if (!role) {
      fieldName.push("Role");
    }
    if (!termsAndConditions) {
      toast.error("Please accept Terms and Conditions!");
      isError = true;
    }
    if (!captchaToken) {
      fieldName.push("Captcha");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (email.length && !validator.isEmail(email)) {
      toast.error("Email is Invalid!");
      isError = true;
    }
    if (password !== cPassword) {
      toast.error("Passwords do not match!");
      isError = true;
    }

    const phoneNumberValidation = /^[+]?\d+$/;
    if (
      phoneNumber.length &&
      !validator.isMobilePhone(phoneNumber) &&
      !phoneNumberValidation.test(phoneNumber)
    ) {
      toast.error("Phone Number is Invalid!");
      isError = true;
    }

    if (isError) {
      return;
    }
    createUser();
  };

  function createUser() {
    axios
      .post(baseUrl + "/register", {
        name: name,
        email: email,
        phone: phoneNumber,
        password: password,
        password_confirmation: cPassword,
        role: role,
      })
      .then((response) => {
        const data = response.data;
        localStorage.setItem("token", data.token);
        toast.info(
          "We've sent an email to " +
            email +
            " to verify your email address and activate your account."
        );
        toast.success("Registration Successful!");
        navigate("/auth/login");
      })
      .catch(function (error) {
        const tempdata = error.response.data;

        if (tempdata.errors) {
          const errorKeys = Object.keys(tempdata.errors);
          for (const errorKey of errorKeys) {
            for (const error of tempdata.errors[errorKey]) {
              toast.error(`${error}`);
            }
          }
        }
      });
  }

  return (
    <div className="row">
      <div
        className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
        style={{ backgroundImage: "url(/images/login-bg-2.jpg)" }}
      />
      <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-lg overflow-hidden">
        <div className="card shadow-none border-0 ml-auto mr-auto login-card">
          <div className="card-body rounded-0 text-left">
            <h2 className="fw-700 display1-size display2-md-size mb-4">
              Create <br />
              your account
            </h2>
            <form>
              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-user text-grey-500 pr-0" />
                <input
                  type="text"
                  className="style2-input pl-5 form-control text-grey-900 font-xsss fw-600"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-email text-grey-500 pr-0" />
                <input
                  type="text"
                  className="style2-input pl-5 form-control text-grey-900 font-xsss fw-600"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-mobile text-grey-500 pr-0" />
                <input
                  type="text"
                  className="style2-input pl-5 form-control text-grey-900 font-xsss fw-600"
                  placeholder="Phone Number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                />
              </div>
              <div className="form-group icon-input mb-3">
                <input
                  type="Password"
                  className="style2-input pl-5 form-control text-grey-900 font-xss ls-3"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <i className="font-sm ti-lock text-grey-500 pr-0" />
              </div>
              <div className="form-group icon-input mb-3">
                <input
                  type="Password"
                  className="style2-input pl-5 form-control text-grey-900 font-xss ls-3"
                  placeholder="Confirm Password"
                  onChange={(e) => setCpassword(e.target.value)}
                  value={cPassword}
                />
                <i className="font-sm ti-lock text-grey-500 pr-0" />
              </div>
              <div className="form-group icon-input mb-3">
                <select
                  className="style2-input pl-5 form-control text-grey-900 font-xsss ls-3"
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="Select Role">Select Role</option>
                  {roleOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <i className="font-sm ti-shield text-grey-500 pr-0" />
              </div>
              <div className="form-check text-left mb-3">
                <input
                  type="checkbox"
                  className="form-check-input mt-2"
                  id="terms-and-conditions"
                  onChange={(e) => setTermsAndConditions(e.target.value)}
                />
                <label
                  className="form-check-label font-xsss text-grey-500"
                  htmlFor="terms-and-conditions"
                >
                  I've read and accept the Terms &amp; Conditions
                </label>
              </div>
            </form>
            <div className="col-sm-12 p-0 text-left">
              <div className="form-group mb-1 my-4 d-flex justify-content-center">
                <ReCAPTCHA
                  sitekey={env.RECAPTCHA_SITE_KEY}
                  onChange={onSubmitWithReCAPTCHA}
                />
              </div>
              <div className="form-group mb-1">
                <button
                  className={
                    "form-control text-center style2-input text-white fw-600 border-0 p-0" +
                    (captchaToken ? " bg-primary" : " bg-dark")
                  }
                  onClick={(e) => handleSubmit(e)}
                  disabled={!captchaToken}
                >
                  Register
                </button>
              </div>
              <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                Already have an account ?
                <Link to="/auth/login" className="fw-700 ml-1">
                  Login
                </Link>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

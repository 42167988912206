import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import env from "../../../../env";
import axios from "axios";

function ImprovementInitiatives() {
  const navigate = useNavigate();
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let isError = false;

  const [mode, setMode] = useState("add");
  const [users, setUsers] = useState([]);
  const [improvementInitiatives, setImprovementInitiatives] = useState([]);
  const [improvementInitiative, setImprovementInitiative] = useState({});
  const [improvementName, setImprovementName] = useState("");
  const [initiatedBy, setInitiatedBy] = useState("");
  const statusOptions = [
    { value: "todo", label: "Todo" },
    { value: "in-progress", label: "In Progress" },
    { value: "completed", label: "Completed" },
    { value: "failed", label: "Failed" },
  ];
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (!users.length) {
      getInitiatives();
      getUsers();
    }
  });

  const getInitiatives = () => {
    axios
      .get(baseUrl + "/improvement-initiatives", config)
      .then((response) => {
        setImprovementInitiatives(response.data.improvements);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getUsers = () => {
    axios
      .get(baseUrl + "/users", config)
      .then((response) => {
        const usrs = [];
        response.data.users.forEach((user) => {
          usrs.push({ value: user.id, label: user.name });
        });
        setUsers(usrs);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleStatusChange = (obj) => {
    setStatus(obj);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fieldName = [];
    if (!improvementName?.length) {
      fieldName.push("Improvement Name");
    }
    if (!initiatedBy) {
      fieldName.push("Initiated By");
    }
    if (!status) {
      fieldName.push("Status");
    }
    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }
    if (isError) {
      return;
    }
    if (mode === "add") {
      addImprovementInitiative();
    } else {
      updateImprovementInitiative();
    }
  };

  const addImprovementInitiative = () => {
    axios
      .post(
        baseUrl + "/improvement-initiative",
        {
          name: improvementName,
          user_id: initiatedBy.value,
          status: status.value,
        },
        config
      )
      .then((response) => {
        toast.success(response.data.message);
        clearFields();
        getInitiatives();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const updateImprovementInitiative = () => {
    axios
      .post(
        baseUrl + "/improvement-initiative/" + improvementInitiative.id,
        {
          name: improvementName,
          user_id: initiatedBy.value,
          status: status.value,
        },
        config
      )
      .then((response) => {
        toast.success(response.data.message);
        clearFields();
        getInitiatives();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const clearFields = () => {
    setImprovementName("");
    setInitiatedBy("");
    setStatus("");
    setMode("add");
  };

  const editImprovementInitiative = (improvement) => {
    setImprovementInitiative(improvement);
    setImprovementName(improvement.name);
    setInitiatedBy(users.find((user) => user.value === improvement.user_id));
    setStatus(
      statusOptions.find((option) => option.value === improvement.status)
    );
    setMode("edit");
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Improvement Initiative
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Improvement Name
                </label>
                <input
                  type="text"
                  name="improvement-name"
                  className="form-control"
                  value={improvementName}
                  onChange={(e) => setImprovementName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Initiated By
                </label>
                <Select
                  isSearchable={false}
                  options={users}
                  value={initiatedBy}
                  onChange={(obj) => setInitiatedBy(obj)}
                  placeholder="Select User"
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Status</label>
                <Select
                  isSearchable={false}
                  options={statusOptions}
                  onChange={handleStatusChange}
                  value={status}
                  placeholder="Select Status"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w250 rounded-lg d-inline-block"
                onClick={handleSubmit}
              >
                {mode === "add" ? "Add" : "Update"} Improvement Initiative
              </Link>
            </div>
          </div>
        </form>
      </div>
      {improvementInitiatives?.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">ID</th>
                <th className="bg-current text-white">Initiated Name</th>
                <th className="bg-current text-white">Initiated By</th>
                <th className="bg-current text-white">Status</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {improvementInitiatives.map((impInit) => (
                <tr key={impInit.id}>
                  <td>{impInit.id}</td>
                  <td>{impInit.name}</td>
                  <td>{impInit.user.name}</td>
                  <td className="text-capitalize">{impInit.status}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current"
                      style={{ cursor: "pointer" }}
                      onClick={() => editImprovementInitiative(impInit)}
                      onKeyDown={(e) => {}}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ImprovementInitiatives;

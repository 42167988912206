import { Link } from "react-router-dom";
import Analytics from "./Analytics";

function ManageAdmin() {
  return (
    <div>
      <Analytics />
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-6 d-flex">
          <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-xxl-5 p-4 border-0 text-center">
            <h4 className="fw-700 font-xs">Students</h4>
            <div className="clearfix" />
            <Link
              to="/dashboard/admin/manage-students"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Manage Students
            </Link>
            <Link
              to="/dashboard/admin/manage-courses"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Manage Courses
            </Link>
            <Link
              to="/dashboard/admin/reports"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Reports & Performance
            </Link>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 d-flex">
          <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-xxl-5 p-4 border-0 text-center">
            <h4 className="fw-700 font-xs">Instructors</h4>
            <div className="clearfix" />
            <Link
              to="/dashboard/admin/manage-instructors"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Manage Instructors
            </Link>
            <Link
              to="/dashboard/admin/handle-courses"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Handle Courses
            </Link>
            <Link
              to="/dashboard/admin/course-content"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Manage Course Content
            </Link>
            <Link
              to="/dashboard/admin/manage-course-material"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Manage Course Material
            </Link>
            <Link
              to="/dashboard/admin/manage-assignments"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Assignment Management
            </Link>
            <Link
              to="/dashboard/admin/handle-exams"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Handle Exams
            </Link>
            {/* <Link
              to="/dashboard/admin/reviews"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Reviews
            </Link> */}
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 d-flex">
          <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-xxl-5 p-4 border-0 text-center">
            <h4 className="fw-700 font-xs">Coordinator</h4>
            <div className="clearfix" />
            <Link
              to="/dashboard/admin/manage-coordinators"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Manage Coordinators
            </Link>
            <Link
              to="/dashboard/admin/handle-programs"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Handle Programs
            </Link>
            <Link
              to="/dashboard/admin/support-tickets"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Manage Support Tickets
            </Link>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 d-flex">
          <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-xxl-5 p-4 border-0 text-center">
            <h4 className="fw-700 font-xs">QA Officers</h4>
            <div className="clearfix" />
            <Link
              to="/dashboard/admin/manage-qa"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Manage QA Officers
            </Link>
            <Link
              to="/dashboard/admin/assign-course"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Assign Course
            </Link>
            <Link
              to="/dashboard/admin/support-tickets"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Support & Enquiries
            </Link>
            <Link
              to="/dashboard/admin/improvement-initiatives"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Improvement Initiatives
            </Link>
            {/* <Link
              to="/dashboard/admin/reports"
              className="p-2 mt-4 d-inline-block text-white fw-700 lh-30 rounded-lg w200 text-center font-xsssss ls-3 bg-current"
            >
              Reports & Performance
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageAdmin;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import env from "../../../../env";
import axios from "axios";

function ManageSubmissions() {
  const navigate = useNavigate();
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");

  const [mode, setMode] = useState("add");
  const [submissions, setSubmissions] = useState(null);
  const [submission, setSubmission] = useState({});
  const [assignment, setAssignment] = useState("");
  const [student, setStudent] = useState("");
  const [feedback, setFeedback] = useState("");
  const [grade, setGrade] = useState("");
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("");

  const grades = [
    { value: "A+", label: "A+" },
    { value: "A", label: "A" },
    { value: "A-", label: "A-" },
    { value: "B+", label: "B+" },
    { value: "B", label: "B" },
    { value: "B-", label: "B-" },
    { value: "C+", label: "C+" },
    { value: "C", label: "C" },
    { value: "C-", label: "C-" },
    { value: "D+", label: "D+" },
    { value: "D", label: "D" },
    { value: "D-", label: "D-" },
    { value: "F", label: "F" },
  ];

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    if (!submissions) {
      getSubmissions();
    }
  });

  const getSubmissions = () => {
    axios
      .get(baseUrl + "/submissions", config)
      .then((response) => {
        setSubmissions(response.data.submissions);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const clearFields = () => {
    setMode("add");
    setStudent("");
    setFeedback("");
    setGrade("");
    setFile(null);
    setStatus("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!feedback?.length) {
      toast.error("Feedback is required");
      return;
    }

    if (!grade) {
      toast.error("Grade is required");
      return;
    }

    if (mode === "edit") updateSubmission();
  };

  const updateSubmission = () => {
    const formData = new FormData();
    formData.append("assignment_id", assignment);
    formData.append("student_id", student);
    formData.append("feedback", feedback);
    formData.append("grade", grade);
    formData.append("status", status);

    axios
      .post(baseUrl + "/submission/" + submission.id, formData, config)
      .then((response) => {
        toast.success(response.data.message);
        getSubmissions();
        clearFields();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const editSubmission = (ass) => {
    setMode("edit");
    setSubmission(ass);
    setAssignment(ass.assignment_id);
    setStudent(ass.student_id);
    setFeedback(ass.feedback);
    setGrade(ass.grade);
    setStatus(ass.status);
    setFile(ass.file);
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Manage Submissions
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Assignment</label>
                <input
                  type="text"
                  className="form-control"
                  value={submission?.assignment?.title}
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Student</label>
                <input
                  type="text"
                  className="form-control"
                  value={submission?.student?.name}
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Submission File
                </label>
                <br />
                <Link
                  to={file || ""}
                  target="_blank"
                  className="btn btn-primary mt-1 mb-0 font-xsss p-2"
                  style={{ width: "100%" }}
                  download
                >
                  Download File
                </Link>
              </div>
            </div>
            <div className="col-lg-8 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Feedback</label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  onChange={(e) => setFeedback(e.target.value)}
                  value={feedback}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Grade</label>
                <select
                  className="form-control"
                  name="grades"
                  id="grades"
                  onChange={(e) => setGrade(e.target.value)}
                  value={grade}
                >
                  <option value="">Select Grade</option>
                  {grades.map((grade) => (
                    <option key={grade.value} value={grade.value}>
                      {grade.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {mode === "edit" && (
            <div className="row">
              <div className="col-lg-12 text-right">
                <button
                  type="button"
                  className="btn btn-primary mt-3 mb-0"
                  onClick={handleSubmit}
                >
                  Update Submission
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
      {submissions?.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Submission ID</th>
                <th className="bg-current text-white">Assignment</th>
                <th className="bg-current text-white">Student</th>
                <th className="bg-current text-white">Feedback</th>
                <th className="bg-current text-white">Grade</th>
                <th className="bg-current text-white">File</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {submissions.map((assign) => (
                <tr key={assign.id}>
                  <td>{assign.id}</td>
                  <td>{assign.assignment.title}</td>
                  <td>{assign.student.name}</td>
                  <td>{assign.feedback || "Yet to be reviewed"}</td>
                  <td>{assign.grade || "Yet to be reviewed"}</td>
                  <td>
                    <Link
                      className="text-primary"
                      to={assign.file}
                      target="_blank"
                    >
                      Download
                    </Link>
                  </td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current"
                      style={{ cursor: "pointer" }}
                      onClick={() => editSubmission(assign)}
                      onKeyDown={(e) => {}}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ManageSubmissions;

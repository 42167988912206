import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import env from "../../../../env";
import axios from "axios";
import Select from "react-select";

function Reports() {
  const navigate = useNavigate();
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  const [reports, setReports] = useState(null);
  const [reportName, setReportName] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filters, setFilters] = useState([]);
  const [filter, setFilter] = useState("");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const filterTypes = [
    { value: "assignments", label: "Assignments" },
    { value: "exams", label: "Exams" },
    { value: "students", label: "Students" },
  ];

  useEffect(() => {
    if (!reports) {
      getReports();
    }
  });

  const getReports = () => {
    axios
      .get(baseUrl + "/reports", config)
      .then((response) => {
        setReports(response.data.reports);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isError = false;
    if (!reportName.length) {
      isError = true;
      toast.error("Report Name is Required");
    }
    if (!filterType?.value) {
      isError = true;
      toast.error("Filter Type is Required");
    }
    if (!filter?.value) {
      isError = true;
      toast.error("Filter is Required");
    }
    if (isError) {
      return;
    }
    addReport();
  };

  const addReport = () => {
    axios
      .post(
        baseUrl + "/report",
        {
          user_id: user.id,
          name: reportName,
          filter_id: filter.value,
          filter: filterType.value,
        },
        config
      )
      .then((response) => {
        toast.success(response.data.message);
        getReports();
        clearFields();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const clearFields = () => {
    setReportName("");
    setFilterType("");
    setFilter("");
  };

  const handleFilterTypeChange = (obj) => {
    setFilter("");
    setFilterType(obj);
    populateFilters(obj);
  };

  const populateFilters = (filter_type) => {
    if (filter_type.value === "assignments") {
      axios
        .get(baseUrl + "/assignments", config)
        .then((response) => {
          let assignments = [];
          response.data.assignments.forEach((assignment) => {
            assignments.push({ value: assignment.id, label: assignment.title });
          });
          setFilters(assignments);
        })
        .catch((error) => {
          const tempdata = error.response.data;
          toast.error(`${tempdata.message}`);
        });
    } else if (filter_type.value === "exams") {
      axios
        .get(baseUrl + "/exams", config)
        .then((response) => {
          let exams = [];
          response.data.exams.forEach((exam) => {
            exams.push({ value: exam.id, label: exam.name });
          });
          setFilters(exams);
        })
        .catch(function (error) {
          const tempdata = error.response.data;
          toast.error(`${tempdata.message}`);
        });
    } else if (filter_type.value === "students") {
      axios
        .get(baseUrl + "/users", config)
        .then((response) => {
          let students = [];
          response.data.users.forEach((user) => {
            if (user.role === "student") {
              students.push({ value: user.id, label: user.name });
            }
          });
          setFilters(students);
        })
        .catch((error) => {
          const tempdata = error.response.data;
          toast.error(`${tempdata.message}`);
        });
    }
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">Reports</h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Report Name
                </label>
                <input
                  type="text"
                  name="report-name"
                  className="form-control"
                  onChange={(e) => setReportName(e.target.value)}
                  value={reportName}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Filter Type
                </label>
                <Select
                  isSearchable={false}
                  options={filterTypes}
                  value={filterType}
                  onChange={(obj) => handleFilterTypeChange(obj)}
                  placeholder="Select Filter Type"
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Filter</label>
                <Select
                  options={filters}
                  value={filter}
                  onChange={(obj) => setFilter(obj)}
                  placeholder="Select Filter"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Generate Report
              </Link>
            </div>
          </div>
        </form>
      </div>
      {reports?.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Report ID</th>
                <th className="bg-current text-white">Report Name</th>
                <th className="bg-current text-white">Filter Type</th>
                <th className="bg-current text-white">Filter</th>
                <th className="bg-current text-white">Generated by</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report) => (
                <tr key={report.id}>
                  <td>{report.id}</td>
                  <td>{report.name}</td>
                  <td className="text-capitalize">{report.filter}</td>
                  <td>{report.filter_name}</td>
                  <td>{report.user.name}</td>
                  <td className="text-center">
                    <Link
                      className="feather-download text-green"
                      style={{ cursor: "pointer" }}
                      to={report.file}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Reports;

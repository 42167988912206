export default function About() {
  return (
    <div>
      <div className="about-wrapper bg-after-fluid pb-lg--7 pt-lg--7 pt-5 pb-7 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="text-grey-900 fw-700 display1-size display2-md-size pb-2 mb-0 mt-3 d-block lh-3">
                About Us
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h4 className="fw-500 mb-4 lh-30 font-xsss text-grey-600 mt-0">
                Our Masters in Computer Science program is dedicated to
                providing students with an exceptional learning experience in
                the field of computer science. With a rigorous curriculum,
                accomplished faculty, and state-of-the-art resources, we prepare
                our students to excel in the dynamic world of technology.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="how-to-work">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-4">
              <img
                src="images/about.jpg"
                alt="about"
                className="rounded-lg img-fluid shadow-xs"
              />
            </div>
            <div className="col-lg-6 offset-lg-1 page-title style1">
              <h2 className="fw-700 text-grey-800 display1-size display2-md-size lh-3 pt-lg--5">
                Performance Measurement and Assessment
              </h2>
              <p className="font-xsss fw-400 text-grey-500 lh-28 mt-0 mb-0 mt-3 w-75 w-xs-90">
                At our Masters in Computer Science program, we recognize the
                vital role of performance measurement and assessment in
                optimizing the learning journey of our students.
              </p>
              <h4 className="fw-600 font-xs mt-5">
                <i className="ti-check btn-round-xs text-white bg-success mr-2 border" />
                Individual Progress Tracking
              </h4>
              <h4 className="fw-600 font-xs">
                <i className="ti-check btn-round-xs text-white bg-success mr-2 border" />
                Data-Driven Insights
              </h4>
              <h4 className="fw-600 font-xs">
                <i className="ti-check btn-round-xs text-white bg-success mr-2 border" />
                Continuous Improvements
              </h4>
              <h4 className="fw-600 font-xs">
                <i className="ti-check btn-round-xs text-white bg-success mr-2 border" />
                Encouraging Healthy Competition
              </h4>
              <h4 className="fw-600 font-xs">
                <i className="ti-check btn-round-xs text-white bg-success mr-2 border" />
                Preparation for Success
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

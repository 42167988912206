import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import env from "../../../env";
import axios from "axios";
import { toast } from "react-toastify";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { setMessageCount } from "../../../redux/slices/app";
import { useDispatch } from "react-redux";

export default function Chat() {
  const dispatch = useDispatch();

  const baseUrl = env.BASE_URL;
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const [chatsLoaded, setChatsLoaded] = useState(false);
  const [allChats, setAllChats] = useState([]);
  const [chats, setChats] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [chatHeader, setChatHeader] = useState(null);
  const [message, setMessage] = useState("");
  const [recording, setRecording] = useState(false);
  const ref = useRef(null);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      return <span>Browser doesn't support speech recognition.</span>;
    }
    if (!chatsLoaded) {
      fetchChats();
    }
    if (activeChat) {
      if (ref.current) scrollToBottom();
    }
  });

  const fetchUsers = () => {
    const groupedChats = [];
    axios
      .get(baseUrl + "/users", config)
      .then((response) => {
        const usersList = response.data.users;
        for (const userItem of usersList) {
          if (userItem.id !== user.id) {
            groupedChats.push({
              id: userItem.id,
              user: userItem,
              message: "",
              unread: 0,
            });
          }
        }
        setChats(groupedChats);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const fetchChats = () => {
    axios
      .get(baseUrl + "/chats", config)
      .then((response) => {
        if (!response.data.chats.length) {
          fetchUsers();
        } else {
          setAllChats(response.data.chats);
          groupChats(response.data.chats);
        }
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
    setChatsLoaded(true);
  };

  const groupChats = (chats) => {
    const groupedChats = [];
    for (const chat of chats) {
      const senderId = chat.sender_id;
      const receiverId = chat.receiver_id;

      if (senderId === user.id) {
        handleSenderChat(groupedChats, receiverId, chat);
      }

      if (receiverId === user.id) {
        handleReceiverChat(groupedChats, senderId, chat);
      }
    }
    setChats(groupedChats);
  };

  const handleSenderChat = (groupedChats, receiverId, chat) => {
    const chatExist = groupedChats.some(
      (groupedChat) => groupedChat.id === receiverId
    );
    if (!chatExist) {
      groupedChats.push({
        id: receiverId,
        user: chat.receiver,
        message: chat.message,
        unread: 0,
      });
    }
  };

  const handleReceiverChat = (groupedChats, senderId, chat) => {
    const chatExist = groupedChats.some(
      (groupedChat) => groupedChat.id === senderId
    );
    if (!chatExist) {
      groupedChats.push({
        id: senderId,
        user: chat.sender,
        message: chat.message,
        unread: chat.status === "unread" ? 1 : 0,
      });
    } else {
      groupedChats.find((groupedChat) => groupedChat.id === senderId).unread +=
        chat.status === "unread" ? 1 : 0;
    }
  };

  const selectChat = (id) => {
    chats.find((chat) => chat.id === id).unread = 0;
    setActiveChat(id);
    setChatHeader(chats.find((chat) => chat.id === id).user);
    fetchConversation(id);
    const sum = chats.reduce((a, b) => a + b.unread, 0);
    dispatch(setMessageCount(sum));
  };

  const fetchConversation = (id) => {
    config.params = {
      receiver_id: id,
    };

    axios
      .get(baseUrl + "/conversation", config)
      .then((response) => {
        setConversation(response.data.conversation);
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const sendMessage = () => {
    const formData = new FormData();
    formData.append("sender_id", user.id);
    formData.append("receiver_id", activeChat);
    formData.append("message", message);

    axios
      .post(baseUrl + "/chat", formData, config)
      .then((response) => {
        setMessage("");
        fetchConversation(activeChat);
        fetchChats();
      })
      .catch(function (error) {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  const handleMicrophone = () => {
    if (transcript) resetTranscript();
    if (!recording) {
      SpeechRecognition.startListening({ continuous: true });
    } else {
      SpeechRecognition.abortListening();
      SpeechRecognition.stopListening();
      setMessage(transcript);
    }
    setRecording(!recording);
  };

  useEffect(() => {
    const sum = chats.reduce((a, b) => a + b.unread, 0);
    dispatch(setMessageCount(sum));
  }, [chats, dispatch]);

  return (
    <div
      className="middle-sidebar-left bg-white dark-bg-transparent mr-3 pr-0 rounded-xxl"
      style={{ height: "fit-content", bottom: "50%" }}
    >
      <div className="row">
        <div className="col-lg-6 col-xl-4 col-md-6 chat-left scroll-bar border-right-light pl-4 pr-4">
          <form action="#" className="mt-0 pl-3 pt-3">
            <div className="search-form">
              <i className="ti-search font-xs" />
              <input
                type="text"
                className="form-control mb-0 bg-greylight border-0"
                placeholder="Search"
                onChange={(e) => {
                  if (e.target.value === "") {
                    groupChats(allChats);
                  } else if (chats.length > 0 && e.target.value.length > 1) {
                    setChats(
                      chats.filter(
                        (chat) =>
                          chat.id !== user.id &&
                          chat.user.name
                            .toLowerCase()
                            .startsWith(e.target.value.toLowerCase())
                      )
                    );
                  } else {
                    fetchUsers();
                  }
                }}
              />
            </div>
          </form>
          <div className="section full mt-2 mb-2 pl-3">
            <ul className="list-group list-group-flush">
              {chats?.map((chat) => (
                <li
                  className="bg-transparent list-group-item no-icon pl-0"
                  key={chat.id}
                  onClick={() => selectChat(chat.id)}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  <figure className="avatar float-left mb-0 mr-3">
                    <img
                      src={chat.user.image}
                      alt="user-7.png"
                      className="w45"
                      style={{ cursor: "pointer" }}
                    />
                  </figure>
                  <h3 className="fw-700 mb-0 mt-1">
                    <Link className="font-xsss text-grey-900 text-dark d-block">
                      {chat.user.name}
                    </Link>
                  </h3>
                  <span className="d-block" style={{ cursor: "pointer" }}>
                    {chat.message}
                  </span>
                  <span
                    className="badge badge-primary text-white badge-pill"
                    style={{ cursor: "pointer" }}
                  >
                    {chat.unread || ""}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-lg-6 col-xl-8 col-md-6 pl-0 d-none d-lg-block d-md-block">
          {activeChat && (
            <div>
              <div className="chat-header clearfix border-bottom mb-3 p-3">
                <div className="chat-form">
                  <figure className="avatar float-left mb-0 mr-3">
                    <img
                      src={chatHeader.image}
                      alt="profile"
                      className="w30"
                      style={{ cursor: "pointer" }}
                    />
                  </figure>
                  <h3 className="fw-700 mb-0 mt-1">
                    <Link className="font-xsss text-grey-900 text-dark d-block">
                      {chatHeader.name}
                    </Link>
                  </h3>
                  <div className="text-grey-500 font-xssss text-capitalize">
                    Role : {chatHeader.role}
                  </div>
                </div>
              </div>
              <div
                className="chat-wrapper pt-0 w-100 position-relative scroll-bar"
                ref={ref}
                style={{ maxHeight: "calc(100vh - 250px)", overflowY: "auto" }}
              >
                <div className="chat-body p-3">
                  <div className="messages-content pb-5 h-100">
                    {conversation?.map((conv) => (
                      <div
                        className={
                          "message-item" +
                          (conv.sender_id === user.id
                            ? " outgoing-message"
                            : "")
                        }
                        key={conv.id}
                      >
                        <div className="message-user">
                          <figure className="avatar">
                            <img src={conv.sender.image} alt="user" />
                          </figure>
                          <div>
                            <h5>{conv.sender.name}</h5>
                            <div className="time">
                              {new Date(conv.created_at).toLocaleTimeString()}
                            </div>
                          </div>
                        </div>
                        <div className="message-wrap">{conv.message}</div>
                      </div>
                    ))}
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
              <div className="chat-bottom dark-bg p-3 shadow-xss rounded-xxl">
                <div className="chat-form">
                  <button
                    className="bg-grey float-left"
                    onClick={handleMicrophone}
                  >
                    <i
                      className={
                        "ti-microphone " +
                        (recording ? "text-red" : "text-grey-600")
                      }
                    ></i>
                  </button>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Send a Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          sendMessage();
                        }
                      }}
                    />
                  </div>
                  <button className="bg-current" onClick={sendMessage}>
                    <i className="ti-arrow-right text-white" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

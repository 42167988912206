import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import env from "../../../../env";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";

function ProgramOverviewAndAlignment() {
  const baseUrl = env.BASE_URL;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const navigate = useNavigate();
  let isError = false;
  const [mode, setMode] = useState("add");

  const [programs, setPrograms] = useState([]);
  const [program, setProgram] = useState(null);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [alignment, setAlignment] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (!coordinators.length) {
      getCoordinators();
      getPrograms();
    }
  });

  const getCoordinators = () => {
    axios
      .get(baseUrl + "/role-users", {
        ...config,
        params: {
          role: "coordinator",
        },
      })
      .then((response) => {
        const coords = [];
        response.data.users.forEach((coord) => {
          coords.push({ value: coord.id, label: coord.name });
        });
        setCoordinators(coords);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const getPrograms = () => {
    axios
      .get(baseUrl + "/programs", config)
      .then((response) => {
        setPrograms(response.data.programs);
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let fieldName = [];
    if (!coordinator) {
      fieldName.push("Coordinator");
    }
    if (!name.length) {
      fieldName.push("Program Name");
    }
    if (!description.length) {
      fieldName.push("Program Description");
    }
    if (!duration.length) {
      fieldName.push("Duration");
    }
    if (!alignment.length) {
      fieldName.push("Alignment");
    }
    if (!status.length) {
      fieldName.push("Status");
    }

    if (fieldName.length) {
      for (const field of fieldName) {
        toast.error(field + " is Required!");
      }
      isError = true;
    }

    if (isError) {
      return;
    }
    mode === "add" ? addProgram() : updateProgram();
  };

  const clearFields = () => {
    setProgram(null);
    setCoordinator("");
    setName("");
    setDescription("");
    setDuration("");
    setAlignment("");
    setStatus("");
    setMode("add");
  };

  const addProgram = () => {
    const newProgram = {
      coordinator_id: coordinator,
      name: name,
      description: description,
      duration: duration,
      alignment: alignment,
      curriculum: "",
      goals: "",
      evaluation: "",
      courses: [],
      status: status,
    };

    axios
      .post(baseUrl + "/program", newProgram, config)
      .then((response) => {
        toast.success(response.data.message);
        getPrograms();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });

    clearFields();
  };

  const editProgram = (prog) => {
    setMode("edit");
    setProgram(prog);
    setCoordinator(prog.coordinator_id);
    setName(prog.name);
    setDescription(prog.description);
    setDuration(prog.duration);
    setAlignment(prog.alignment);
    setStatus(prog.status);
  };

  const updateProgram = () => {
    axios
      .post(
        baseUrl + "/program/" + program.id,
        {
          coordinator_id: coordinator,
          name: name,
          description: description,
          duration: duration,
          alignment: alignment,
          curriculum: program.curriculum,
          goals: program.goals,
          evaluation: program.evaluation,
          courses: program.courses.map((course) => course.id),
          status: status,
        },
        config
      )
      .then((response) => {
        toast.success("Program Overview and Alignment Updated Successfully!");
        getPrograms();
      })
      .catch((error) => {
        const tempdata = error.response.data;
        toast.error(`${tempdata.message}`);
      });

    clearFields();
  };

  const handleCoordinatorChange = (selectedOption) => {
    setCoordinator(selectedOption);
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "completed", label: "Completed" },
  ];

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption.value);
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
          Program Overview and Alignment
        </h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Coordinator
                </label>
                <Select
                  isSearchable={false}
                  options={coordinators}
                  value={coordinators.find(
                    (option) => option.value === coordinator
                  )}
                  onChange={(e) => (e ? handleCoordinatorChange(e.value) : "")}
                  placeholder="Select Coordinator"
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Program Name
                </label>
                <input
                  type="text"
                  name="course-name"
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Program Duration
                </label>
                <input
                  type="text"
                  name="course-name"
                  className="form-control"
                  onChange={(e) => setDuration(e.target.value)}
                  value={duration}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Program Description
                </label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Alignment</label>
                <textarea
                  className="w-100 h100 style2-textarea p-3 form-control"
                  onChange={(e) => setAlignment(e.target.value)}
                  value={alignment}
                />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Status</label>
                <Select
                  options={statusOptions}
                  value={statusOptions.find(
                    (option) => option.value === status
                  )}
                  onChange={handleStatusChange}
                  placeholder="Course Status"
                />
              </div>
            </div>
            <div className="col-lg-12 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link
                className="bg-current text-center text-white font-xsss fw-600 p-3 w300 rounded-lg d-inline-block"
                onClick={(e) => handleSubmit(e)}
              >
                {mode === "add" ? "Add" : "Edit"} Program Overview and Alignment
              </Link>
            </div>
          </div>
        </form>
      </div>
      {programs.length > 0 && (
        <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
          <table className="table table-bordered data-table">
            <thead>
              <tr>
                <th className="bg-current text-white">Program ID</th>
                <th className="bg-current text-white">Coordinator Name</th>
                <th className="bg-current text-white">Program Name</th>
                <th className="bg-current text-white">Program Description</th>
                <th className="bg-current text-white">Program Duration</th>
                <th className="bg-current text-white">Program Alignment</th>
                <th className="bg-current text-white">Status</th>
                <th className="bg-current text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {programs.map((program) => (
                <tr key={program.id}>
                  <td>{program.id}</td>
                  <td>{program.coordinator.name}</td>
                  <td>{program.name}</td>
                  <td>{program.description}</td>
                  <td>{program.duration}</td>
                  <td>{program.alignment}</td>
                  <td className="text-capitalize">{program.status}</td>
                  <td className="text-center">
                    <i
                      className="feather-edit text-current"
                      style={{ cursor: "pointer" }}
                      onClick={() => editProgram(program)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default ProgramOverviewAndAlignment;

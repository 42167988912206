import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

const courses = [
  { value: "Operating Systems", label: "Operating Systems" },
  { value: "Networking", label: "Networking" },
  { value: "Algorithms", label: "Algorithms" },
  { value: "Compiler Design", label: "Compiler Design" },
  { value: "System Design", label: "System Design" },
];

const students = [
  { value: "Chad Nicolas", label: "Chad Nicolas" },
  { value: "Noah Buckridge", label: "Noah Buckridge" },
  { value: "Sherman Herman", label: "Sherman Herman" },
  { value: "David Durgan", label: "David Durgan" },
  { value: "Hannah Dach", label: "Hannah Dach" },
  { value: "Jesus Hermiston", label: "Jesus Hermiston" },
  { value: "Douglas Hayes PhD", label: "Douglas Hayes PhD" },
  { value: "Merle Gerlach", label: "Merle Gerlach" },
];

const instructors = [
  { value: "Colin Heller", label: "Colin Heller" },
  { value: "Billy Cremin", label: "Billy Cremin" },
  { value: "Edmund Christiansen", label: "Edmund Christiansen" },
  { value: "Maurice Maggio", label: "Maurice Maggio" },
];

function Reviews() {
  const navigate = useNavigate();

  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedInstructors, setSelectedInstructors] = useState([]);

  const handleCourseSelection = (selectedValues) => {
    setSelectedCourses(selectedValues);
  };

  const handleStudentSelection = (selectedValues) => {
    setSelectedStudents(selectedValues);
  };

  const handleInstructorelection = (selectedValues) => {
    setSelectedInstructors(selectedValues);
  };

  return (
    <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div className="card-body p-3 w-100 bg-current border-0 d-flex rounded-lg">
        <Link onClick={() => navigate(-1)} className="d-inline-block mt-2">
          <i className="ti-arrow-left font-sm text-white" />
        </Link>
        <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">Reviews</h4>
      </div>
      <div className="card-body p-lg-5 p-4 w-100 border-0 mb-0">
        <form action="#">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Students</label>
                <Select
                  isMulti
                  options={students}
                  value={selectedStudents}
                  onChange={handleStudentSelection}
                  placeholder="Select Students"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Instructors
                </label>
                <Select
                  isMulti
                  options={instructors}
                  value={selectedInstructors}
                  onChange={handleInstructorelection}
                  placeholder="Select Instructors"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">Courses</label>
                <Select
                  isMulti
                  options={courses}
                  value={selectedCourses}
                  onChange={handleCourseSelection}
                  placeholder="Select Courses"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-4 d-flex align-items-end justify-content-end pb-2 pr-3">
              <Link className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block">
                Filter Reviews
              </Link>
            </div>
          </div>
        </form>
      </div>

      <div className="card-body pl-lg-5 pr-lg-5 w-100 table-responsive">
        <table className="table table-bordered data-table">
          <thead>
            <tr>
              <th className="bg-current text-white">Student Name</th>
              <th className="bg-current text-white">Instructor Name</th>
              <th className="bg-current text-white">Course</th>
              <th className="bg-current text-white">Review</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Marcia Hirthe</td>
              <td>Elisa Kling</td>
              <td>System Design</td>
              <td>
                <div className="star-rating">
                  <i className="feather-star" />
                  <i className="feather-star" />
                  <i className="feather-star" />
                  <i className="feather-star" />
                </div>
                <div className="review-text">This course was excellent!</div>
              </td>
            </tr>
            <tr>
              <td>Rosalie Hyatt</td>
              <td>Ollie Jakubowski</td>
              <td>Operating System</td>
              <td>
                <div className="star-rating">
                  <i className="feather-star" />
                  <i className="feather-star" />
                  <i className="feather-star" />
                </div>
                <div className="review-text">
                  A highly informative and engaging course!
                </div>
              </td>
            </tr>
            <tr>
              <td>Sean Feeney</td>
              <td>Tami Rogahn</td>
              <td>Algorithms</td>
              <td>
                <div className="star-rating">
                  <i className="feather-star" />
                  <i className="feather-star" />
                  <i className="feather-star" />
                  <i className="feather-star" />
                  <i className="feather-star" />
                </div>
                <div className="review-text">
                  Instructor has solid foundations. Quick to answer questions.
                  Friendly as well. Good job.
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Reviews;

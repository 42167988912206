import { Outlet } from "react-router-dom";

export default function Student() {
  return (
    <div
      className="middle-sidebar-left border-0 bg-white shadow-xs p-4 col-lg-9 mr-3 rounded-xxl"
      style={{ height: "fit-content" }}
    >
      <Outlet />
    </div>
  );
}
